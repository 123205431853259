import React, { useEffect, useState } from "react";
import StrategicCardList from "./StrategicCard/StrategicCardList";
import s from "./scorecards.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreCardStrategicInitiatives } from "../../action/actionScoreCard";
import { MONTH_SHORT_TEXT } from "../../constants/score-card.constants";
const StrategicInitiativesComponent = ({ dateFilterValue }) => {
  const [activeId, setActiveId] = useState(0);
  const [activeHighlight, setActiveHighlight] = useState(null);
  const scoreCard = useSelector((state: any) => state.scoreCard);
  const { strategicInitiatives } = scoreCard;
  const handleOpenHighlight = (key) => {
    setActiveHighlight(key);
  };
  const dispatch = useDispatch();
  const handleStrategicSelect = (index) => {
    setActiveHighlight(null);
    setActiveId(index);
  };
  useEffect(() => {
    const params = {
      month: MONTH_SHORT_TEXT[dateFilterValue?.month - 1],
      year: dateFilterValue?.year,
    };
    if (dateFilterValue?.month && dateFilterValue?.year)
      dispatch(fetchScoreCardStrategicInitiatives({ params }));
  }, []);
  
  if (!strategicInitiatives.category || strategicInitiatives.category.length === 0) {
    return (
        <div className={s.noResult}>
          <img src="/img/icon/no-result.svg" alt="No results" />
          <h2>No data available!</h2>
        </div>
        
    );
  }
  return (
    
    <div className={s.strategicContainer}>
      <ul className={s.strategicHeader}>
        {strategicInitiatives?.categories?.map((item, index) => {
          return (
            <li
              key={item.category}
              onClick={() => handleStrategicSelect(index)}
              className={
                activeId === index
                  ? `${s.strategicHeaderItem} ${s.strategicItemActive}`
                  : s.strategicHeaderItem
              }
            >
              {item.category}
            </li>
          );
        })}
      </ul>
      <div className={s.trategicBody}>
        {
          <div>
            {strategicInitiatives && strategicInitiatives?.categories && (
              <StrategicCardList
                objectives={
                  strategicInitiatives?.categories[activeId]?.objectives
                }
                category={strategicInitiatives?.categories[activeId]?.category}
                handleOpenHighlight={handleOpenHighlight}
                activeHighlight={activeHighlight}
              />
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default StrategicInitiativesComponent;
