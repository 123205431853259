import { flatten, isEmpty, uniq, values } from "lodash";

import { GNE_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.gne.constants";
import { LNGA_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.lnga.constants";
import { MLNG_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.mlng.constants";
import { PFLNG_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.pflng.constants";
import { SESSION_STORAGE_KEY, USER_UPN_MAPPING, VIEW_TYPE } from "../constants/user.constants";
import MapComponent from "../container/Map/MapComponent";
import MftComponent from "../container/Mft/MftComponent";
import ScoreCardsComponent from "../container/ScoreCards/ScoreCardsComponent";
import ValueChainComponent from "../container/ValueChain/ValueChainComponent";
import SustainabilityComponent from "../container/Sustainability/SustainabilityComponent";
import RiskProfileComponent from "../container/RiskManagement/RiskProfile/RiskProfileComponent";
import { OPU_UPCS } from "../constants/opu.constants";
import { LMT_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.lmt.constants";
import { GP_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.gp.constants";
import { PEGT_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.pegt.constants";
import { PGB_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.pgb.constants";
import { OTHERS_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.others.constants";
import { PDSB_ADMIN_ROUTE } from "../constants/RoutesPersona/admin.pdsb.constants";
import AssuranceComponent from "../container/RiskManagement/MyAssurance/AssuranceComponent";

export const getUserUPN = () => {
  let userEmail: string = "";
  if (sessionStorage.getItem(SESSION_STORAGE_KEY.USER_EMAIL)) {
    userEmail = sessionStorage.getItem(
      SESSION_STORAGE_KEY.USER_EMAIL
    ) as string;
  }
  const userUPNMapping = USER_UPN_MAPPING;
  return (
    userUPNMapping.get(userEmail) ||
    sessionStorage.getItem(SESSION_STORAGE_KEY.USER_UPN)
  );
};

export const isUserAdmin = () => {
  const userRole: any = sessionStorage.getItem(SESSION_STORAGE_KEY.USER_ROLE);
  if (userRole) {
    return (
      userRole.indexOf("Super Admin") > -1 || userRole.indexOf("Admin") > -1
    );
  }
  return false;
};

export const isSuperAdmin = () => {
  const userRole: any = sessionStorage.getItem(SESSION_STORAGE_KEY.USER_ROLE);
  if (userRole) {
    return userRole.indexOf("Super Admin") > -1;
  }
  return false;
};

export const getUserPersona = () => {
  const userPersona: any = sessionStorage.getItem(
    SESSION_STORAGE_KEY.USER_PERSONA
  );
  if (userPersona) {
    return JSON.parse(userPersona);
  }
};

export const userRoutes: any = () => {
  const userPersona = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY.USER_PERSONA) as string);
  const selectedPersona = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY.SELECTED_PERSONA) as string);
  if (userPersona) {
    const selectedOPU = selectedPersona?.opuName ? selectedPersona?.opuName : userPersona.opu
    switch (selectedOPU) {
      case OPU_UPCS.GNE:
        return GNE_ADMIN_ROUTE;
      case OPU_UPCS.MLNG:
        return MLNG_ADMIN_ROUTE;
      case OPU_UPCS.LNGA:
        return LNGA_ADMIN_ROUTE;
      case OPU_UPCS.PFLNG:
        return PFLNG_ADMIN_ROUTE;
      case OPU_UPCS.LMT:
        return LMT_ADMIN_ROUTE;
      case OPU_UPCS.GP:
        return GP_ADMIN_ROUTE;
      case OPU_UPCS.PEGT:
        return PEGT_ADMIN_ROUTE;
      case OPU_UPCS.PGB:
        return PGB_ADMIN_ROUTE;
      case OPU_UPCS.OTHERS:
        return OTHERS_ADMIN_ROUTE;
      case OPU_UPCS.PDSB:
        return PDSB_ADMIN_ROUTE;
    }
  } else {
    return GNE_ADMIN_ROUTE;
  }
};

export const userDefaultMap = (mapLocations: any) => {
  let defaultMap = {
    level: 1,
    locationId: 1,
  };
  if (mapLocations) {
    mapLocations.forEach((value) => {
      if (value.isDefault) {
        defaultMap = value;
      }
    });
  }
  return defaultMap;
};

export const getDefaultMap = () => {
  const userMapLocations = sessionStorage.getItem(SESSION_STORAGE_KEY.USER_MAP);
  let defaultMap = {
    level: 1,
    locationId: 1,
  };
  if (userMapLocations) {
    const mapLocationsArr = JSON.parse(userMapLocations);
    mapLocationsArr?.forEach((item) => {
      if (item.isDefault) {
        defaultMap = item;
      }
    });
  }

  return defaultMap;
};

export const getSustainabilityMap = () => {
  let defaultMap = {
    level: 1,
    locationId: 1,
  };

  return defaultMap;
};

export const isAllowLocation = (mapLocations: any) => {
  const userMapLocations = sessionStorage.getItem(SESSION_STORAGE_KEY.USER_MAP);
  let isAllowed = false;
  if (userMapLocations) {
    const mapLocationsArr = JSON.parse(userMapLocations);
    mapLocationsArr.forEach((value) => {
      if (value.locationId === mapLocations) {
        isAllowed = true;
      }
    });
  }

  return isAllowed;
};

export const getAllowedViews = () => {
  const viewPermissions = sessionStorage.getItem(
    SESSION_STORAGE_KEY.VIEW_PERMISSION
  ) as string;
  return !isEmpty(viewPermissions)
    ? values(JSON.parse(viewPermissions)).map((v) => ({
      allowedViews: v.allowedViews,
      defaultView: v.defaultView,
      viewType: v.viewType,
    }))
    : [];
};

export const buildRouteBaseOnAllowViews = (data) => {
  
  const allowViewArr: any = uniq([
    ...data.allowedViews.map((v) => v.viewUrl),
    data.defaultView?.viewUrl,
  ]);
  // console.log(allowViewArr,"allowViewArr")
  let routeComponent: any = "";
  switch (data.viewType) {
    case VIEW_TYPE.MAP:
      routeComponent = MapComponent;
      break;
    case VIEW_TYPE.VALUE_CHAIN:
      routeComponent = ValueChainComponent;
      break;
    case VIEW_TYPE.SCORE_CARDS:
      routeComponent = ScoreCardsComponent;
      break;
    case VIEW_TYPE.MFT_SCORE_CARDS:
      routeComponent = MftComponent;
      break;
    case VIEW_TYPE.SUSTAINABILITY:
      routeComponent = SustainabilityComponent;
      break;
    case VIEW_TYPE.RISK_PROFILE:
      routeComponent = RiskProfileComponent;
      break;
    case VIEW_TYPE.ASSURANCE:
      routeComponent = AssuranceComponent;
      break;
    default:
      routeComponent = MapComponent;
      break;
  }
  return allowViewArr.map((v) => ({
    key: v,
    path: v,
    exact: true,
    component: routeComponent,
  }));
};

export const getRouteBaseOnAllowViews = () => {
  var viewPermissions = getAllowedViews();
  // Add Sustainability to allowedViews
  var sustainabilityView = {
    viewType: "Sustainability",
    allowedViews: [],
    defaultView: {
      viewUrl: "/sustainability"
    }
  }
  viewPermissions.push(sustainabilityView);
  
  return flatten(viewPermissions.map((v) => buildRouteBaseOnAllowViews(v)));
};

export const getDefaultView = (pathName, viewType, selectedPersona: any) => {
  const splitPathnameArr = pathName.split("/");
  const viewPermissions = sessionStorage.getItem(
    SESSION_STORAGE_KEY.VIEW_PERMISSION
  ) as string;
  const defaultModule = values(JSON.parse(viewPermissions)).filter(
    (v) => v.viewType === viewType
  )?.[0];
  const currentPage = splitPathnameArr[splitPathnameArr.length - 1];
  const defaultViewUrl = selectedPersona? selectedPersona?.[currentPage] : defaultModule?.defaultView?.viewUrl;
  const safePath = !defaultViewUrl || isValidInternalPath(defaultViewUrl) ? defaultViewUrl : '/'; // Fallback to a safe route

  return safePath;
};

export const isValidInternalPath = (path) => path.startsWith('/'); // Ensure the path is internal