import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../../constants/responsive.constants";
import s from "./BaseLmtSalesSummaryChart.module.css";
import LmtSalesBySourceSelectionComponent from "./LmtSalesBySourceSelectionComponent";
import { useSelector } from "react-redux";

export interface LmtSalesStackedChartComponentProps {
    chartData: any | null;
    chartName: string;
}

export default function LmtSalesStackedChartComponent({ chartData, chartName }: LmtSalesStackedChartComponentProps) {
    const chartNames = {
        SALES_BY_CUSTOMER: "Top 5 Sales By Customer",
        SALES_BY_SOURCE: "Sales by Source",
        SALES_BY_TERMSPOT: "Sales by Term/Spot",
        SALES_BY_SEASON: "Sales by Season",
        SALES_BY_INCOTERMS: "Sales by Incoterms"
    };

    const uniqueID = window.crypto['randomUUID']();

    const customerColors = ["#F06800", "#ECC972", "#637AB8", "#8BC5F5", "#F5BB8B"];
    const sourceColors = ["#385711", "#477E00", "#5AA000", "#75D000", "#A5FF33", "#C7FF81"];
    const termSpotColors = ["#284095", "#89F1EB"];
    const seasonColors = ["#0A7A29", "#F0AB00"];
    const incotermColors = ["#8BC5F5", "#E4527E"];

    const { selectedLmtSource } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            selectedLmtSource: scorecard.selectedLmtSource,
        };
    });

    const [showSourceFilter, setShowSourceFilter] = useState(false);
    const [selectedSources, setSelectedSources] = useState<string[]>([]);

    const chart = useRef<am4charts.XYChart>();
    let chartColor: string[] = [];

    let transformData: any[] = [];

    let sourceData: any[] = [];
    let tempSourceData: any[] = [];

    switch (chartName) {
        case chartNames.SALES_BY_CUSTOMER:
            chartColor = customerColors;
            break;
        case chartNames.SALES_BY_SOURCE:
            prepareSourceData();
            chartColor = sourceColors;
            break;
        case chartNames.SALES_BY_TERMSPOT:
            chartColor = termSpotColors;
            break;
        case chartNames.SALES_BY_SEASON:
            chartColor = seasonColors;
            break;
        case chartNames.SALES_BY_INCOTERMS:
            chartColor = incotermColors;
            break;
        default:
            break;
    }

    function prepareSourceData() {
        if (chartData && chartData.length) {
            for (let idx = 0; idx < chartData.length; idx++) {
                if (tempSourceData.indexOf(chartData[idx].category) < 0) {
                    tempSourceData.push(chartData[idx].category);
                    sourceData.push({ source: chartData[idx].category, selected: true });
                }
            }
        }
    }

    function renderSelectionText(selected: string[]) {
        let selectionText = "";
        if (selected.length === 0) {
            return "All";
        }

        selectionText += selected[0]

        if (selected.length >= 2) {
            selectionText += ', ' + selected[1];
        }

        return selectionText + `${selected.length > 2 ? `, +${selected.length - 2}` : ''}`;
    }

    function handleSourceSelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowSourceFilter(!isShow)
        }
        else {
            setSelectedSources([...selectedLmtSource])

            // Close dropdown
            setShowSourceFilter(!isShow);
        }
    }

    function renderSourceFilter() {
        return (
            <div className={s.filterBlock}>
                <div className={`${s.filterBlockDropdown} ${showSourceFilter ? s.show : ''}`}>
                    <button
                        type="button"
                        style={{ width: "110px" }}
                        onClick={() => handleSourceSelection(showSourceFilter)}
                    >
                        {renderSelectionText(selectedLmtSource)}
                        <i className="mdi mdi-menu-down"></i>
                    </button>
                    {(chartData && chartData.length > 0 && showSourceFilter) && (
                        <div className={s.filterSelectionDropdownMenu}>
                            <LmtSalesBySourceSelectionComponent sourceData={sourceData} />
                        </div>)}
                </div>
            </div>);
    }

    let prepareChartData: any[] = [];
    if (chartData) {
        if (chartName === chartNames.SALES_BY_SOURCE && selectedSources.length > 0) {
            prepareChartData = chartData.filter(c => {
                return selectedSources.indexOf(c.category) >= 0;
            })
        }
        else {
            prepareChartData = chartData
        }

        transformData = Object.values(prepareChartData?.reduce(function (transform, current) {
            const { year } = current;
            if (!transform[year]) {
                transform[year] = { year: current.year, customers: [] }
            }
            transform[year].customers.push({ category: current.category, value: current.value });
            return transform;
        }, {}));

        for (let idx = 0; idx < transformData.length; idx++) {
            let customers = transformData[idx].customers
            customers.map(function (obj) {
                // Get name of category
                const categoryName = obj['category'];
                // Create new obj with categoryName
                //transformData[idx][categoryName] = {[categoryName]: obj['value'], category: obj['category']};
                transformData[idx][categoryName] = obj['value'];
                return;
            });
            delete transformData[idx].customers;
        }
    }

    const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
    const isTablet = useMediaQuery(TABLET_RESPONSIVE);

    useEffect(() => {
        if (!isEmpty(transformData)) {
            const chartdiv = am4core.create(chartName + uniqueID, am4charts.XYChart);
            chart.current = chartdiv;
            const chartCurrent = chart.current;
            chartCurrent.zoomOutButton.disabled = true;
            if (chartCurrent) {
                if (transformData.length > 0) {
                    chartCurrent.background.fillOpacity = 0;
                    chartCurrent.maskBullets = false;
                    chartCurrent.height = 300;
                    chartCurrent.cursor = new am4charts.XYCursor();
                    chartCurrent.cursor.lineY.disabled = true;
                    chartCurrent.cursor.behavior = "none";
                    if (chartCurrent.logo) {
                        chartCurrent.logo.disabled = true;
                    }

                    // Legend
                    chartCurrent.legend = new am4charts.Legend();
                    /* Create a separate container to put legend in */
                    var legendContainer = am4core.create(chartName + uniqueID + "-legenddiv", am4core.Container);
                    legendContainer.width = am4core.percent(100);
                    legendContainer.height = am4core.percent(100);
                    chartCurrent.legend.parent = legendContainer;
                    chartCurrent.legend.itemContainers.template.paddingTop = 10; 
                    chartCurrent.legend.itemContainers.template.paddingBottom = 5; 
                    legendContainer.logo.disabled = true;
                    // chartCurrent.legend.height = 40;
                    // chartCurrent.legend.scrollable = true;

                    // chartCurrent.events.on("datavalidated", (ev) => {
                    //     document.getElementById("legenddiv")!.style.height = chartCurrent.legend.contentHeight + "px";
                    // });
                    // chartCurrent.events.on("maxsizechanged", (ev) => {
                    //     document.getElementById("legenddiv")!.style.height = chartCurrent.legend.contentHeight + "px";
                    // });

                    // Colors
                    let colorSet = new am4core.ColorSet();
                    colorSet.step = 40;
                    for (let idx = 0; idx < chartColor.length; idx++) {
                        chartCurrent.colors.list.push(am4core.color(chartColor[idx]));
                    }

                    // Color generator
                    for (let idx = 0; idx < 20; idx++) {
                        chartCurrent.colors.list.push(colorSet.next());
                    }
                    customizeDateAxis(chartCurrent);
                    customizeValueAxis(chartCurrent);

                    chartCurrent.data = transformData;
                }
            }
        }

        // Add data
        return () => {
            chart.current?.dispose();
        };
    }, [prepareChartData]);

    function customizeDateAxis(chart: am4charts.XYChart) {
        const yearAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        yearAxis.dataFields.category = "year";
        yearAxis.renderer.minGridDistance = 10;
        // yearAxis.renderer.ticks.template.disabled = false;
        yearAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        yearAxis.fontSize = 11;
        yearAxis.renderer.grid.template.disabled = true;
        // yearAxis.renderer.grid.template.location = 0;
        // yearAxis.renderer.labels.template.disabled = true;
        yearAxis.cursorTooltipEnabled = false;
        yearAxis.dateFormatter.dateFormat = "yyyy";
        yearAxis.numberFormatter = new am4core.NumberFormatter();
        yearAxis.numberFormatter.numberFormat = "#";
        (isMobile || isTablet) &&
            chart.events.on("ready", function () {
                const year = new Date().getFullYear();
                yearAxis.zoomToCategories(
                    year.toString(), (year + 1).toString()
                );
                chart.zoomOutButton.disabled = true;
            });
    }

    function customizeValueAxis(
        chart: am4charts.XYChart
    ) {
        // Create series
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.fill = am4core.color("#ffffffa6");
        valueAxis.renderer.labels.template.fontSize = 12;

        let keys: string[] = [];
        for (let idx = 0; idx < transformData.length; idx++) {
            for (let key in transformData[idx]) {
                if (transformData[idx].hasOwnProperty(key) && keys.indexOf(key) < 0) {
                    keys.push(key);
                }
            }
        }

        for (let keyIdx = 0; keyIdx < keys.length; keyIdx++) {
            if (keys[keyIdx] === 'year') continue;
            createBarChart(
                chart,
                valueAxis,
                keys[keyIdx],
                keys[keyIdx]
            );
        }
    }

    function customizeTooltip(value) {
        let tooltipHTML = `<table style='line-height:14px;'>`;
        tooltipHTML +=
            `<tr style='font-size:13px;'>
            <td>
                <span style="height: 14px;
                            width: 24px;
                            color: #C8CCCD;
                            font-family: 'Museo Sans';">
                    ${value}
                </span>
            </td>
        </tr>`;
        tooltipHTML += `</table>`;
        return tooltipHTML;
    }

    function createBarChart(
        chart: am4charts.XYChart,
        valueAxis: am4charts.ValueAxis,
        seriesName: string,
        valueField: string
    ) {
        var barSeries = chart.series.push(new am4charts.ColumnSeries());
        barSeries.name = seriesName;
        barSeries.dataFields.valueY = valueField;
        barSeries.dataFields.categoryX = "year";
        barSeries.yAxis = valueAxis;
        barSeries.columns.template.width = 25;
        barSeries.tooltipHTML = `<div>{valueY.formatNumber('#.##')}</div>`; // Display only the value
        chart.cursor.maxTooltipDistance = 0;
        barSeries.stacked = true;

        const lineTooltip = barSeries.tooltip;
        if (lineTooltip) {
            lineTooltip.pointerOrientation = "down";
            lineTooltip.getFillFromObject = false;
            lineTooltip.background.fill = am4core.color("#051212");
            lineTooltip.background.opacity = 0.95;
            lineTooltip.background.strokeOpacity = 0;
        }
    }

    // function _legendChart() {
    //     return (
    //         <div className={s.legendSection}>

    //             <div className={s.legendBlock}>
    //                 {keys.map((item, idx) => (
    //                     <div key={idx} className={s.legendGroup}>
    //                         <div className={s.legend} style={{ backgroundColor: item.color }} />
    //                         <p>{item.label}</p>
    //                     </div>
    //                 ))}
    //             </div>
    //         </div>
    //     );
    // }

    const isSaleBySource = chartName === chartNames.SALES_BY_SOURCE;
    return (
        <>
            {chartData && chartData.length > 0 ?
                (
                    <div className={s.chartContainer}>
                        <div className={isSaleBySource ? s.sourceChartHeader : s.chartHeader}>
                            {chartName}
                            {/* {isSaleBySource && renderSourceFilter()} */}
                        </div>
                        <div id={chartName + uniqueID} className={s.salesChart}></div>
                        <div id={chartName + uniqueID + "-legenddiv"} className={s.legendDiv}></div>
                        {/* <div className="legendwrapper">
                        <div id="legenddiv"></div>
                    </div> */}
                    </div>
                ) :
                (
                    <div className={s.noChartContainer}>
                        <div className={s.noResult}>
                            <img src="/img/icon/no-result.svg" alt="No results" />
                            <h2>No data available!</h2>
                        </div>
                    </div>
                )
            }
        </>
    );
};
