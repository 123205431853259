import { get } from 'lodash';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '../../../utils/callout.utils';

import s from '../Highlight/highlight.module.css';
import sForm from '../../ReleaseContent/CreateUpdateForm/CreateUpdateForm.module.css'

import { CONFIRM_MODAL } from '../../../constants/content-release.constants';
import { toggleAnnouncementForm } from '../../../action/actionContentRelease';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ModalConfirm from '../../ModalConfirm/ModalConfirm';
import { postSustainabilitySummary } from '../../../action/actionSustainability';
import { fetchSustainabilityCalloutData } from '../../../action/actionMap';
import { SESSION_STORAGE_KEY } from '../../../constants/user.constants';
import { parseToHtml } from '../../../utils/common.utils';


export default function EmissionSummaryComponent() {
    const { callOutData } = useSelector((state) => {
        const callout = get(state, 'callout', null);
        return {
            callOutData: callout.sustainabilityCalloutData,
        };
    });

    const userPersona: any = JSON.parse(sessionStorage.getItem(
        SESSION_STORAGE_KEY.USER_PERSONA
    ) as string);

    const isOpenForm = useSelector((state) =>
        get(state, 'contentRelease.isOpenForm', false)
    );

    const isFormLoading = useSelector((state) =>
        get(state, 'contentRelease.isFormLoading', false)
    );

    const updatedDate = callOutData?.secondarySection?.summary.updatedDate
    const summary = callOutData?.secondarySection?.summary?.keyFinding?.value ?? "";

    const [confirmKey, setConfirmKey] = useState<any>(null);
    const [updatedSummary, setUpdatedSummary] = useState(summary);

    const dispatch = useDispatch();

    function renderCreateUpdateForm() {
        return (
            <div className={sForm.summaryContentDetailPanel}>
                <div className={sForm.summaryContentDetailContainer}>
                    {renderGroupBtn()}
                    {renderEditBox()}
                </div>
                {renderConfirmModal()}
            </div>
        )
    }

    function toggleForm(isOpen) {
        dispatch(toggleAnnouncementForm(isOpen));
    }

    function saveSummary(updatedSummary) {
        const isDaily = true;
        const data = {
            locationId: callOutData?.locationId,
            keyFinding: updatedSummary,
            updatedBy: 'Cu Vi'
        };
        dispatch(postSustainabilitySummary(data));
        dispatch(toggleAnnouncementForm(false));
        dispatch(fetchSustainabilityCalloutData(callOutData?.locationId, isDaily))
    }

    function closeModal() {
        setConfirmKey(null);
    }

    function renderEditBox() {
        return (
            <div className={sForm.formItem}>
                <label>Edit Summary</label>
                <div id='summary-content-editor'>
                    <CKEditor
                        data={summary}
                        config={{ placeholder: 'Enter your content' }}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                            setUpdatedSummary(editor.getData())
                        }}
                    />
                </div>
            </div>
        );
    }

    function renderGroupBtn() {
        return (
            <div className={sForm.summaryGroupBtn}>
                <button
                    className={btnClassName('secondaryBtn', isFormLoading)}
                    disabled={isFormLoading}
                    onClick={() => toggleForm(false)}
                    type='button'
                >
                    Cancel
                </button>
                <button
                    disabled={isFormLoading}
                    className={btnClassName('primaryBtn', isFormLoading)}
                    onClick={() => {
                        setConfirmKey(CONFIRM_MODAL.SAVE.KEY)
                    }}
                    type='button'
                >
                    Save
                </button>
            </div>
        );
    }

    function renderConfirmModal() {
        if (confirmKey) {
            switch (confirmKey) {
                case CONFIRM_MODAL.SAVE.KEY: {
                    return (
                        <ModalConfirm
                            message={CONFIRM_MODAL.SAVE.MESSAGE}
                            type={CONFIRM_MODAL.SAVE.KEY}
                            onConfirm={() => {
                                saveSummary(updatedSummary);
                            }}
                            onClose={closeModal}
                        />
                    );
                }
                default: {
                    return;
                }
            }
        }
    }

    function btnClassName(type, isDisabled) {
        return `${sForm[type]}` + (isDisabled ? ` ${sForm.disabled}` : '');
    }

    function renderAsHtml() {
        return (
            <div>{parseToHtml(summary)}</div>
        );
    }
    if (summary && !isOpenForm) {
        return (
            <div className={s.sustainabilitySummaryPanel}>
                <div className={s.sustainabilitySummaryHeader}>
                    <span className={s.sustainabilitySummaryDate}>
                        <img src='/img/icon/ic-time.svg' alt='time' />
                        &nbsp; {formatDate(updatedDate)}
                    </span>
                    {(userPersona.opu === "Gas Business" && (userPersona.personaName === "Admin" || userPersona.personaName === "Developer")) &&
                        <span className={s.summaryEdit}>
                            <img src="/img/icon/Pencil.svg"
                            alt="edit"
                            onClick={() => toggleForm(true)} 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    toggleForm(true);
                                }
                            }}
                            tabIndex={0}/>
                        </span>}
                </div>
                <div className={s.highlightDesc}>
                    <span className={s.highlightItemDescription}>{renderAsHtml()}</span>
                </div>
            </div>
        );
    }
    else if (summary && isOpenForm) {
        return (
            <div className={s.sustainabilitySummaryPanel}>
                {renderCreateUpdateForm()}
            </div>
        );
    }

    return <></>;
}
