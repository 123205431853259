import Modal from 'antd/lib/modal/Modal';
import { get } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';

import s from './ModalContentReleaseAnnouncement.module.css';
import {
  closeModalContentRelease,
  fetchContentReleaseAnnouncement,
} from '../../action/actionContentRelease';
import { parseToHtml } from '../../utils/common.utils';
const ModalContentReleaseAnnouncement = (props: any) => {
  const dispatch = useDispatch();

  const isVisibleModal = useSelector((state) =>
    get(state, 'contentRelease.isVisibleAnnouncement', false)
  );

  const slides = useSelector((state) =>
    get(state, 'contentRelease.announcementData.slides', [])
  );

  useEffect(() => {
    let announcement = localStorage.getItem('announcement');
    if (!announcement) {
    dispatch(fetchContentReleaseAnnouncement());
      localStorage.setItem('announcement', '1');
    }
  }, []);

  const handleCancel = () => {
    dispatch(closeModalContentRelease());
  };

  function _renderCarousel() {
    if (slides.length) {
      return (
        <Carousel
          className={s.crCarousel}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
        >
          {slides.map((slide) => {
            return <>{_renderSlideItem(slide)}</>;
          })}
        </Carousel>
      );
    }
  }

  function _renderSlideItem(slide) {
    const { content, imageUrl, slideId, slideTitle } = slide;

    return (
      <div key={slideId} className={s.slideItem}>
        <div className={s.imgContainer}>
          {imageUrl && <img alt='release-slide' src={imageUrl} />}
        </div>
        <div className={s.slideContent}>
          {slideTitle && <p className={s.slideTitle}>{slideTitle}</p>}
          {content && (
            <p className={s.slideDescription}>
              <div>{parseToHtml(content)}</div>
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        className='content-release-announcement'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        width={960}
        zIndex={1000}
        maskClosable={false}
      >
        {_renderCarousel()}
      </Modal>
    </>
  );
};
export default ModalContentReleaseAnnouncement;
