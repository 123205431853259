import React, { useEffect, useState } from 'react'
import s from './CustomerDetailComponent.module.css'
import { LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SALES_UNITS_ENUMS } from '../../../../../constants/score-card.constants'
import { Col, DatePicker, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { fetchLmtSalesCountryData, fetchScoreCardLmtChartDetail, fetchScoreCardLmtCustomerChartDetail, fetchScorecardLmtContractDetailTable } from '../../../../../action/actionScoreCard';
import LmtSalesStackedChartComponent from '../LmtSalesStackedChartComponent';
import LmtSalesListComponent from '../LmtSalesListComponent';
import LmtSalesDeliveredChartComponent from '../LmtSalesDeliveredChartComponent';

interface Iprops {
    customer: string,
    country: string,
    fromYearFilterValue: number,
    setFromYearFilterValue: (fromYearFilterValue: number) => void,
    toYearFilterValue: number,
    setToYearFilterValue: (toYearFilterValue: number) => void,
    unitIndex: number,
    setUnitIndex: (unitIndex: number) => void,
    selectedTab: string
}

const CustomerDetailComponent = (props: Iprops) => {
    const { customer,
        country,
        fromYearFilterValue,
        setFromYearFilterValue,
        toYearFilterValue,
        setToYearFilterValue,
        unitIndex,
        setUnitIndex,
        selectedTab
    } = props

    const dispatch = useDispatch();

    const { chartData, contractDetail, countryData, selectedLmtCountry, selectedLmtCustomer } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardLmtChartModal,
            chartData: scorecard.lmtCustomerChartData,
            contractDetail: scorecard.lmtContractDetailTable,
            countryData: scorecard.lmtCountryData,
            selectedLmtCountry: scorecard.selectedLmtCountry,
            selectedLmtCustomer: scorecard.selectedLmtCustomer,
        };
    });
    //show
    const [showCustomerFilter, setShowCustomerFilter] = useState(false);
    const [showFromYearFilter, setShowFromYearFilter] = useState(false);
    const [showToYearFilter, setShowToYearFilter] = useState(false);
    const [showUnitSelection, setShowUnitSelection] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState('');

    type Customer = {
        listSource: string[];
        customerName: string;
      };
      
      const [customerList, setCustomerList] = useState<Customer[]>([]);
    
    function capitalizeFirstLetter(str) {
        return  str.map(str => str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase());
    }

    function uniqueCustomerList(customerList: Customer[]){
        let uniqueCustomerName = customerList.filter((value, index, array) => {
            return array.findIndex(arr => arr.customerName === value.customerName) === index;
        }).sort((a, b) => {
            return a.customerName.localeCompare(b.customerName);
        });
        return uniqueCustomerName;
    }

    useEffect(() => {

        let request = {
            from: fromYearFilterValue,
            to: toYearFilterValue,
            unit: unitIndex,
            listCountry: country ? [country] : countryData?.filter(item => item?.listCustomerName.includes(selectedCustomer)).map(item => item.countryName),
            listCustomer: [selectedCustomer],
            year: 2022,
            month: 'Feb'
        };

        let requestContractDetails = {
            country: capitalizeFirstLetter(country ? [country] : countryData?.filter(item => item?.listCustomerName.includes(selectedCustomer))
            .map(item => item.countryName)),
            customer: selectedCustomer
        };
        
        dispatch(fetchScoreCardLmtCustomerChartDetail(request));
        dispatch(fetchScorecardLmtContractDetailTable(requestContractDetails));
    }, [fromYearFilterValue, toYearFilterValue, unitIndex, selectedCustomer, customer, country, selectedTab]);

    useEffect(() => {
        const params = `?from=${fromYearFilterValue}&to=${toYearFilterValue}`;
        dispatch(fetchLmtSalesCountryData(params));
    }, [fromYearFilterValue, toYearFilterValue]);

    const getRootContainer = (elementClassName): HTMLElement => {
        const elements = document.getElementsByClassName(elementClassName);
        if (elements.length > 0) {
            return elements[0] as HTMLElement;
        }
        return document.body;
      };
    // Change unit dataset
    const units = LMT_OPERATIONAL_SALES_UNITS;
    let keyIdx = 0;

    function handleChangeUnit(unit: string) {
        let selectedUnitIndex = units.findIndex(v => v == unit);
        setUnitIndex(selectedUnitIndex);
        setShowUnitSelection(false);
    };

    function handleChangeCustomer(customer: string) {
        setSelectedCustomer(customer)
        setShowCustomerFilter(false);
    };

    function handleFromYearSelect($event) {
        setFromYearFilterValue($event.year());
        setShowFromYearFilter(false);
    }

    function handleToYearSelect($event) {
        setToYearFilterValue($event.year());
        setShowToYearFilter(false);
    }

    function displaySelectedYear(selectedYear: any) {
        if (selectedYear)
            return selectedYear;
        return "?";
    }

    function handleCustomerSelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowCustomerFilter(!isShow)
        }
        else {
            setSelectedCustomer && setSelectedCustomer(selectedCustomer)
            // Close dropdown
            setShowCustomerFilter(!isShow);
        }
    }

    function renderSelectionText(selected: string[]) {
        let selectionText = "";
        if (selected.length === 0) {
            return "All";
        }

        selectionText += selected[0]

        return selectionText + `${selected.length > 1 ? `, +${selected.length - 1}` : ''}`;
    }

    useEffect(() => {
        const selectCustomerList = countryData?.find((item) => item?.countryName.toUpperCase() === country?.toUpperCase())?.listCustomerName
        
        if (selectCustomerList) {
            setCustomerList(selectCustomerList)
        }

        
    }, [country,countryData]);

    useEffect(() => {
        if (customerList && customerList[0]) {
            setSelectedCustomer(customerList[0][0]);
        }
        if(customer) {
            setSelectedCustomer(customer)
        }
    }, [customerList, customer]);

    useEffect(() => {
        if(!countryData?.countryName)
        {
            const customerListAll = countryData.flatMap(customer => customer?.listCustomerName);
            setCustomerList(uniqueCustomerList(customerListAll))
        }       
    }, [country, customer, fromYearFilterValue, toYearFilterValue,countryData]);

    useEffect(() => {
        setShowCustomerFilter(false)
        setShowFromYearFilter(false)
        setShowToYearFilter(false)
        setShowUnitSelection(false)
    }, [selectedTab])

    return (
        <>
            <div className={s.filterBlocks}>
                <div className={s.filterCountryCustomerBlock}>
                    <div className={s.filterBlock}>
                        <span>CUSTOMER</span>
                        <div
                            className={`${s.filterBlockDropdown} ${showCustomerFilter ? s.show : ''}`}
                        >
                            <button
                                type="button"
                                style={{ width: "218px", justifyContent: 'center' }}
                                onClick={() => handleCustomerSelection(showCustomerFilter)}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setShowCustomerFilter(false)
                                    }, 300);
                                }}
                            >
                                <Tooltip title={selectedCustomer}>
                                    <span className={s.selectedCustomer}> {selectedCustomer}</span>
                                </Tooltip>
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className={s.filterBlockDropdownCustomerMenu}>
                                <ul>
                                    {customerList?.map((customer, id) => {
                                        return (<li key={id}>
                                            <button
                                                type="button"
                                                onClick={() => handleChangeCustomer(customer.customerName)}
                                                className={selectedCustomer === customer.customerName ? s.selected : ''}
                                            >
                                                {customer.customerName}
                                            </button>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.filterPeriodUnitBlock}>
                    <div className={s.filterBlock}>
                        <span>FROM</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowFromYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowFromYearFilter(!showFromYearFilter)
                                }
                            >
                                {displaySelectedYear(fromYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${fromYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleFromYearSelect}
                                        open={showFromYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>TO</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowToYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowToYearFilter(!showToYearFilter)
                                }
                            >
                                {displaySelectedYear(toYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${toYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleToYearSelect}
                                        open={showToYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>UNIT</span>
                        <div className={`${s.filterBlockDropdown}
                ${showUnitSelection ? s.show : ''}`}
                        >
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowUnitSelection(!showUnitSelection)
                                }
                            >
                                {units[unitIndex]}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className={s.filterBlockDropdownMenu}>
                                <ul>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT)}
                                            className={units[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT ? s.selected : ''}
                                        >
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT}
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button" onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE)}
                                            className={units[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE ? s.selected : ''}>
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.chartBody}>
                <Row gutter={[10, 10]}>
                    <Col span={6} key={"chart" + keyIdx++}>
                        <LmtSalesDeliveredChartComponent chartData={chartData.saleDelivered} chartName="Sales Volume" />
                    </Col>
                    <Col span={6} key={"chart" + keyIdx++}>
                        <LmtSalesStackedChartComponent chartData={chartData.saleByTermSpot} chartName="Sales by Term/Spot" />
                    </Col>
                    <Col span={6} key={"chart" + keyIdx++}>
                        <LmtSalesStackedChartComponent chartData={chartData.saleBySeason} chartName="Sales by Season" />
                    </Col>
                    <Col span={6} key={"chart" + keyIdx++}>
                        <LmtSalesStackedChartComponent chartData={chartData.saleByIncoterms} chartName="Sales by Incoterms" />
                    </Col>
                    <Col span={8} key={"chart" + keyIdx++}>
                        <LmtSalesListComponent chartData={chartData.saleByPorts} chartName="Sales by Ports" />
                    </Col>
                    <Col span={16} key={"chart" + keyIdx++}>
                        <LmtSalesListComponent chartData={contractDetail?.results} chartName="Contract Details" />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CustomerDetailComponent