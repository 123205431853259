import { useEffect, useState } from "react";

export default function DelayedRender({ delay, children }) {
  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(true);
    }, delay);

    // Clean up the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <div>
      {showElement && children}
    </div>
  );
}