import { useEffect, useState } from 'react'
import { Desktop, Mobile, Tablet } from '../../../responsive/responesive'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import ScrollContainer from 'react-indiana-drag-scroll'
import { LMT_SALES_VOLUME_TAB, LMT_VALUE_CHAIN_STATIC_PROCESS, COUNTRY_LIST } from '../../../constants/value-chain.constants'
import s from './LMTValueChain.module.css'
import BaseLmtSalesSummaryChart from '../../../components/ScoreCards/DetailsChart/LMT/BaseLmtSalesSummaryChart'
import OPUSelectionComponent from '../OPUselectionComponent/OPUselectionComponent'
import { Col, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchValueChainOverviewData } from '../../../action/actionValueChain'
import { get } from 'lodash'
import { fetchCalloutDataSec, fetchMapData } from '../../../action/actionMap'
import { getDefaultMap } from '../../../utils/user.utils'
import { ClockCircleOutlined } from '@ant-design/icons'
import { formatDate } from '../../../utils/callout.utils'
import { fetchLmtSalesCountryData } from '../../../action/actionScoreCard'
import { toFixedValueChainNumber } from "../../../utils/Number.utils";

const MAP_SALE_VALUE = {
    'PLC': "LNG DELIVERY (PLC)",
    'PFLNG 1': "LNG DELIVERY (PFLNG SATU)",
    'PFLNG 2': "LNG DELIVERY (PFLNG DUA)",

}
const LMTValueChainComponent = () => {
    const dispatch = useDispatch();
    const { chartData, countryData, contractData, saleVolumeData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardLmtChartModal,
            chartData: scorecard.lmtCountryChartData,
            countryData: scorecard.lmtCountryData,
            contractData: scorecard.lmtContractData,
            saleVolumeData: scorecard.saleVolumeData
        };
    });
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const [selectedTab, setSelectedTab] = useState(LMT_SALES_VOLUME_TAB.GENERAL);
    const [selectedCountry, setSelectedCountry] = useState(countryData[0]?.countryName)
    const handle = useFullScreenHandle();
    const valueChainOverviewData = useSelector((state) =>
        get(state, "valuechain.valueChainOverviewData", {})
    );
    const mapData = useSelector((state) => get(state, "map.mapData", {}));
    const { callOutData } = useSelector((state) => {
        const callout = get(state, 'callout', null);
        return {
            callOutData: callout.calloutData,
        };
    });
    let timeStamp = ''
    if (Array.isArray(callOutData?.primaryTags)) {
        const primary = callOutData?.primaryTags[0]
        if (Array.isArray(primary?.dataSource)) {
            timeStamp = primary.dataSource[0]?.timeStamp;
        }
    }

    const openLMTSaleAnalysis = (tab: string, country?: string) => {
        setIsVisibleModal(true);
        setSelectedTab(tab);
        if (country) {
            setSelectedCountry(country);
        }
    }

    useEffect(() => {
        dispatch(fetchLmtSalesCountryData());
    }, [])

    const openLMTCountryAnalysis = (tab: string) => {
        setIsVisibleModal(true);
        // <CountryDetailComponent countryFocus={country} />
        setSelectedTab(tab)
    }

    const mappedValueChainOverviewData = valueChainOverviewData.map((item) => {
        const { plantName } = item
        const saleValue = mapData?.result?.locationPoints?.find((item) => item.locationName === MAP_SALE_VALUE[plantName]).parameters[0].value
        if (saleValue) {
            return { ...item, saleValue }
        }
        return { ...item }
    })
    const handleCancelModal = () => {
        setIsVisibleModal(false);
        setSelectedTab(LMT_SALES_VOLUME_TAB.GENERAL)
    }

    const _renderStaticProcess = (config, idx) => {
        return (
            <Col key={idx} span={config.col}>
                <div className={s.progressItem} style={config.text === "sales" ? { marginLeft: '15px' } : {}}>
                    <div className={s.progressItemIcon}>
                        <img src={config?.icon} alt="Static Process" />
                    </div>
                    <div className={s.progressItemText} >
                        {config?.text}
                        {config.text === "sales" && <img
                            className={s.popUpIcon}
                            alt='pop-up'
                            src='/img/icon/popup-with-bg.svg'
                            onClick={() => openLMTSaleAnalysis(LMT_SALES_VOLUME_TAB.GENERAL)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {openLMTSaleAnalysis(LMT_SALES_VOLUME_TAB.GENERAL);}
                            }}
                            tabIndex={0}
                        />}
                    </div>
                    {config.text !== "countries" && (
                        <div className={s.progressItemSeparator}></div>
                    )}
                </div>
            </Col>
        );
    };

    const _renderMobileTablet = () => {
        return <div className={`fullscreen-pt-0 ${s.valueChainRoot}`}>
            <FullScreen handle={handle}>
                <div className="page-action-right">
                    <div
                        className={`full-screen ${handle.active ? "full-screen-exit" : ""
                            }`}
                        onClick={!handle.active ? handle.enter : handle.exit}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {!handle.active ? handle.enter() : handle.exit();}
                        }}
                        tabIndex={0}
                    >
                        <img alt="full_screen" src="/img/icon/full_screen_icon.svg" />
                    </div>
                </div>
                <ScrollContainer
                    className={`page-container bg-map ${s.valueChainContainer}`}
                >
                    <div className="page-content">
                        {/* Progress */}
                        <div className={s.progressTop}>
                            {LMT_VALUE_CHAIN_STATIC_PROCESS.map((config, idx) =>
                                _renderStaticProcess(config, idx)
                            )}
                        </div>
                        {/* PLC table  */}
                        {/* PFLNG1 */}
                        {/* PFLNG2 */}
                    </div>
                </ScrollContainer>
            </FullScreen>
        </div>
    }

    useEffect(() => {
        const defaultMap = getDefaultMap();
        dispatch(fetchValueChainOverviewData())
        dispatch(fetchMapData(defaultMap.locationId));
        dispatch(fetchCalloutDataSec(19));
    }, [])

    const diffFontKeys = ['0PFLNG 1', '0PFLNG 2'];
    const excludeEntryName = ['PFLNG 1', 'PFLNG 2'];

    const convertedCountryList = countryData.map((item) => {return item.countryName} )


    return (
        <>
            <Desktop>
                <div className={`fullscreen-pt-0 ${s.valueChainRoot}`}>
                    <FullScreen handle={handle}>
                        {/* Removed Full Screen Button */}
                        {/* <div className="page-action-right" style={{ top: 136 }}>
                            <div
                                className={`full-screen ${handle.active ? "full-screen-exit" : ""
                                    }`}
                                onClick={!handle.active ? handle.enter : handle.exit}
                            >
                                <img alt="full_screen" src="/img/icon/full_screen_icon.svg" />
                            </div>
                        </div> */}
                        <ScrollContainer
                            className={`page-container bg-map ${s.valueChainContainer}`}
                        >

                            <div className="page-content">
                                <div className={s.pageContainer}>
                                    <OPUSelectionComponent />
                                    {/* Progress */}
                                    <div className={s.progressTop}>
                                        <Row >
                                            {LMT_VALUE_CHAIN_STATIC_PROCESS.map((config, idx) =>
                                                _renderStaticProcess(config, idx)
                                            )}
                                        </Row>
                                    </div>

                                    {/* Commented out, utilize info button. State handling on hover TODO */}
                                    {/* <div className={s.timeStampContainer}>
                                        <div className={s.timeStamp}>
                                            <ClockCircleOutlined style={{ fontSize: '16px', marginRight: '8px' }} />
                                            <span>{timeStamp ? formatDate(timeStamp) : 'N/A'}</span>
                                        </div>
                                    </div> */}

                                    {/* Table Container */}
                                    <div className={s.tableMain}>
                                        <div >
                                            {mappedValueChainOverviewData.map((item, id) => {
                                                const { plantName, capacityTotal, productionTotal, entities, saleValue } = item
                                                return (
                                                    <div className={s.tableContainer}>
                                                        <Row wrap={false}>
                                                            {/* header */}
                                                            <Col span={1} >
                                                                <div className={s.tableHeaderContainer}>
                                                                    <div className={s.tableHeader}>{plantName}</div>
                                                                </div>
                                                            </Col>
                                                            {/* body */}
                                                            <Col span={18}>
                                                                <div className={s.tableBody}>
                                                                    {
                                                                        id === 0 &&
                                                                        <>
                                                                            <Row style={{ marginBottom: '-31px', position: 'relative', transform: 'translateY(-47px)' }}>
                                                                                <Col span={10}></Col>
                                                                                <Col span={6} className={s.headerLeftCell}>Capacity</Col>
                                                                                <Col span={8} className={s.headerRightCell}>Production</Col>
                                                                            </Row>
                                                                        </>
                                                                    }
                                                                    <Row>
                                                                        {/* total */}
                                                                        {(capacityTotal || productionTotal) && <>
                                                                            <Col span={10} ></Col>
                                                                            <Col span={6} className={s.TotalCell}>{toFixedValueChainNumber(capacityTotal, 0)}</Col>
                                                                            <Col span={8} className={`${s.TotalCell} + ${s.TotalRightCell}`}>{toFixedValueChainNumber(productionTotal, 0)}</Col>
                                                                        </>}
                                                                        {entities.map((item, id) => {
                                                                            const { entityName, gasField, capacityValue, productionValue } = item
                                                                            const identifierkey = id + plantName;
                                                                            return <>
                                                                                <Col span={10} className={`${s.gasFieldCellContainer} + ${id !== entities.length - 1 && s.cellBorder}`} style={{ padding: entityName ? '0px' : '8px 0' }} key={id}>
                                                                                    {entityName && <div className={s.entryName} style={{ display: excludeEntryName.includes(entityName) ? 'none' : 'initial' }}>{entityName}</div>}
                                                                                    <div className={s.gasFieldCellWrapper}>
                                                                                        <div className={s.gasFieldCell} style={{ padding: excludeEntryName.includes(entityName) ? '16px 0px 16px 32px' : 'initial' }}>
                                                                                            {gasField}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col >
                                                                                <Col span={6} className={`${s.productionCell} + ${id !== entities.length - 1 && s.cellBorder}`} key={id} style={{ fontSize: diffFontKeys.includes(identifierkey) ? '24px' : '16px', color: diffFontKeys.includes(identifierkey) ? '#00A19C' : '#FFFFFF' }}> {toFixedValueChainNumber(capacityValue, 0)}</Col>
                                                                                <Col span={8} className={`${s.productionCell} + ${s.productionRightCell} + ${id !== entities.length - 1 && s.cellBorder}`} key={id} style={{ fontSize: diffFontKeys.includes(identifierkey) ? '24px' : '16px', color: diffFontKeys.includes(identifierkey) ? '#00A19C' : '#FFFFFF' }}>{toFixedValueChainNumber(productionValue, 0)}</Col >
                                                                            </>
                                                                        })}
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                            {/* footer */}
                                                            <Col span={5} className={s.tableFooterContainer} style={{ position: 'relative' }}>
                                                                <div className={s.tableFooter}>{toFixedValueChainNumber(saleValue, 0)}
                                                                    {/* <img src="/img/icon/info-green.svg" alt="Timestamp"/> */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>)
                                            })}
                                        </div>
                                        {/* SALES Container */}
                                        <div className={s.plccontainerright}>
                                            <div className={s.plctablecontainer}>
                                                {COUNTRY_LIST.map((item, id) => {
                                                    if (item !== "Others") {
                                                        return <div className={s.plctablerowsingle} key={id}>
                                                            <div className={s.plctablerowsinglelabel}>
                                                                {item}
                                                            </div>
                                                            <div className={s.plciconpopup}>
                                                                <img
                                                                    className={s.popUpIcon}
                                                                    alt='country-pop-up'
                                                                    src="/img/icon/popup-with-bg.svg"
                                                                    onClick={() => openLMTSaleAnalysis(LMT_SALES_VOLUME_TAB.COUNTRY_DETAIL, item)} 
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {openLMTSaleAnalysis(LMT_SALES_VOLUME_TAB.COUNTRY_DETAIL, item);}
                                                                    }}
                                                                    tabIndex={0}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    else { return null; }

                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollContainer>
                    </FullScreen>
                </div>
            </Desktop >
            <Mobile>{_renderMobileTablet()}</Mobile>
            <Tablet>{_renderMobileTablet()}</Tablet>
            <BaseLmtSalesSummaryChart isVisibleModal={isVisibleModal} name='LMT Sales Analysis' handleCancelModal={handleCancelModal} selectedTab={selectedTab} setSelectedTab={setSelectedTab} setSelectedCountry={setSelectedCountry} country={selectedCountry} />
        </>
    )
}

export default LMTValueChainComponent