import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { Breadcrumb, DatePicker, Select, Space, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import s from './OPUselection.module.css'
import { updateLevelAndKeyScoreCard } from '../../../action/actionScoreCard';
import { SESSION_STORAGE_KEY } from '../../../constants/user.constants';
import { setSelectedPersona, setSelectedPersonaViewUrl } from '../../../action/actionUsers';
import { BREADCRUMB_VALUECHAIN, OPU_UPCS } from '../../../constants/opu.constants';
import { TAB_SCORE_CARD } from '../../../constants/score-card.constants';
import moment from 'moment';

const OPUSelectionComponent = () => {
    const currentYear = new Date().getFullYear();
    const [showOPUFilter, setShowOPUFilter] = useState(false);
    const [showFromYearFilter, setShowFromYearFilter] = useState(false);
    const [fromYearFilterValue, setFromYearFilterValue] = useState(currentYear - 4);
    const [showToYearFilter, setShowToYearFilter] = useState(false);
    const [toYearFilterValue, setToYearFilterValue] = useState(currentYear);
    const dispatch = useDispatch();
    const history = useHistory();
    const pathName = window.location.pathname;

    const selectedPersona = useSelector((state) =>
        get(state, "user.selectedPersona")
    );

    const scoreCard = useSelector((state: any) => state.scoreCard);
    const {
        currentDate,
        periodsList,
        keyScoreCard,
        levelScoreCard,
        executiveSummary,
        sectionIdActive,
        unitSelected,
    } = scoreCard;

    const personaSelection = useSelector((state) =>
        get(state, "user.personaSelection")
    );

    const buildOPUOptions = (personaSelections: { opuName: string, viewUrl: { scorecards: string } }[]) => {
        const allowedDropdown = personaSelections.filter((personaSelection) => personaSelection.viewUrl.scorecards !== '')
        const convertedAllowedDropdown = allowedDropdown.map(personaSelection => { return { value: personaSelection.opuName, label: personaSelection.opuName } })
        return convertedAllowedDropdown;
    }

    function handleBackLevel() {
        if (levelScoreCard !== 1) {
            dispatch(updateLevelAndKeyScoreCard({ level: 1, tab: sectionIdActive }));
        }
    }
    function displaySelectedYear(selectedYear: any) {
        if (selectedYear)
            return selectedYear;
        return "?";
    }

    function handleFromYearSelect($event) {
        setFromYearFilterValue($event.year());
        setShowFromYearFilter(false);
    }

    function handleToYearSelect($event) {
        setToYearFilterValue($event.year());
        setShowToYearFilter(false);
    }

    const handleChangeOPU = (value: string) => {
        const splitPathnameArr = pathName.split("/");
        personaSelection.forEach((item) => {
            if (item?.opuName === value) {
                if (item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]] !== "") {
                    history.push(item?.viewUrl[splitPathnameArr[splitPathnameArr.length - 1]]);
                    sessionStorage.setItem(SESSION_STORAGE_KEY.SELECTED_PERSONA, JSON.stringify(item))
                    dispatch(setSelectedPersonaViewUrl(item?.viewUrl))
                    dispatch(setSelectedPersona(value));
                } else {
                    notification.open({
                        message: 'Info',
                        duration: 8,
                        className: 'personaNotification',
                        icon: <i className="mdi mdi-information" />,
                        description:
                            `You don't have the access.`
                    });
                }

            }
        });
        setShowOPUFilter(false)
    }

    const breadCrumb = () => {
        return <Breadcrumb
            className={s.mapBreadcrumb}
            separator={
                <img
                    className={s.breadcrumbSeparator}
                    alt='separator-icon'
                    src='/img/icon/arrow-right.svg'
                />
            }
        >
            {BREADCRUMB_VALUECHAIN[selectedPersona]?.map((OPU, index) => {
                return (
                    <Breadcrumb.Item
                        className={`${s.mapBreadcrumbItem} ${index === BREADCRUMB_VALUECHAIN[selectedPersona].length - 1 ? s.currentLocationBcItem : ''
                            }`}
                        key={OPU}
                        onClick={() => handleChangeOPU(OPU === "Gas Business Scorecard" ? OPU_UPCS.GNE : OPU)}
                    >
                        {OPU}
                    </Breadcrumb.Item>
                );
            })}
        </Breadcrumb>
    }

    return (
        <div
            className={`${s.scoreCardsSectionTitle} ${s.scoreCardsSectionTitleWithAction}`}
        >
            {![TAB_SCORE_CARD.FINANCIAL_EXCELLENCE].includes(sectionIdActive) ?
                (<div onClick={() => handleBackLevel()}
                    style={{ cursor: 'pointer' }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleBackLevel();
                        }
                      }}
                    tabIndex={0}
                >
                    {/* {sectionTitle()} <span>Scorecards</span> */}
                    {breadCrumb()}
                    {levelScoreCard !== 1 && (
                        <span>
                            {' '}
                            <img
                                src='/img/icon-score-card/Icon Back.svg'
                                alt='Back'
                            />{' '}
                            Overview
                        </span>
                    )}{' '}
                </div>) :
                (<div>
                    {/* <span>{sectionTitle()} Scorecards</span> */}
                    {breadCrumb()}
                </div>)
            }
            <div className={s.filterContainer}>
                {/* <div className={s.filterBlock}>
                    <span>FROM</span>
                    <div className={`${s.filterBlockDropdown}`}
                        onBlur={() => setShowFromYearFilter(false)}>
                        <button
                            type="button"
                            style={{ width: "110px", justifyContent: 'center' }}
                            onClick={() =>
                                setShowFromYearFilter(!showFromYearFilter)
                            }
                        >
                            {displaySelectedYear(fromYearFilterValue)}
                            <i className="mdi mdi-menu-down"></i>
                        </button>
                        <div className="scorecards-year-picker">
                            <Space direction="vertical">
                                <DatePicker
                                    value={moment(
                                        `${fromYearFilterValue}`,
                                        "YYYY"
                                    )}
                                    onChange={handleFromYearSelect}
                                    open={showFromYearFilter}
                                    picker="year"
                                />
                            </Space>
                        </div>
                    </div>
                </div>
                <div className={s.filterBlock}>
                    <span>TO</span>
                    <div className={`${s.filterBlockDropdown}`}
                        onBlur={() => setShowToYearFilter(false)}>
                        <button
                            type="button"
                            style={{ width: "110px", justifyContent: 'center' }}
                            onClick={() =>
                                setShowToYearFilter(!showToYearFilter)
                            }
                        >
                            {displaySelectedYear(toYearFilterValue)}
                            <i className="mdi mdi-menu-down"></i>
                        </button>
                        <div className="scorecards-year-picker">
                            <Space direction="vertical">
                                <DatePicker
                                    value={moment(
                                        `${toYearFilterValue}`,
                                        "YYYY"
                                    )}
                                    onChange={handleToYearSelect}
                                    open={showToYearFilter}
                                    picker="year"
                                />
                            </Space>
                        </div>
                    </div>
                </div> */}
                {selectedPersona === 'LMT' &&
                <div className={s.filterBlock}>
                    <span>UNIT</span>
                    <span>BCe</span>
                </div>}
                <div className={s.filterBlocks}>
                    <div className={s.filterBlock}>
                        <span >OPU</span>
                        <div
                            className={`${s.filterBlockDropdown}`}
                            onBlur={() => {
                                setTimeout(() => {
                                    setShowOPUFilter(false)
                                }, 500);
                            }}
                        >
                            <button
                                type='button'
                                style={{ width: '140px', justifyContent: 'center' }}
                                onClick={() =>
                                    setShowOPUFilter(!showOPUFilter)
                                }
                            >
                                {selectedPersona}
                                <i className='mdi mdi-menu-down'></i>
                            </button>
                            <div className={`scorecards-OPU-selection`}>
                                <Space direction='vertical'>
                                    <Select
                                        className={s.mapRightPanelSelect}
                                        value={selectedPersona}
                                        onChange={handleChangeOPU}
                                        open={showOPUFilter}
                                        dropdownStyle={{
                                            padding: '0',
                                            background: 'linear-gradient(180deg,rgba(6, 25, 25, 0.9) 0%,#000000 100%)',
                                        }}
                                    >
                                        {buildOPUOptions(personaSelection).map(item => (
                                            <Select.Option value={item.value} key={item.value} className="score-card-OPU-selection-item">
                                                {item.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Space>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <DateDropdown disabledDate={disabledDate} periodsList={periodsList} currentDate={currentDate} onDateChange={onDateChange} /> */}
            </div>
        </div>
    )
}

export default OPUSelectionComponent