import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { openModalWaterfallChart } from "../../../action/actionScoreCard";
import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../constants/responsive.constants";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { customizeScollbar } from "../../../utils/chart.utils";
import { tenKFormatter } from "../../../utils/Number.utils";
import s from "./ChartComponent.module.css";
import sMobile from "./ChartResponsiveMobile.module.css";

const chartColorCode = {
  blue: "#009DDC",
  red: "#E75552",
  green: "#00A19C",
};

const WaterfallChartLevel2 = (props: any) => {
  const dispatch = useDispatch();
  const chart = useRef<am4charts.XYChart>();
  const [date, setDate] = useState("");
  const currentDate = useSelector((state) =>
    get(state, "scoreCard.currentDate", 1)
  );
  const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
  const isTablet = useMediaQuery(TABLET_RESPONSIVE);
  useEffect(() => {
    if (!isEmpty(props.waterfallChart?.data)) {
      const chartdiv = am4core.create("waterfallchartdiv", am4charts.XYChart);
      chart.current = chartdiv;
      const chartCurrent = chart.current;
      chartCurrent.numberFormatter.numberFormat = "###.#a";
      chartCurrent.numberFormatter.smallNumberThreshold = 0;
      chartCurrent.zoomOutButton.disabled = true;
      const formattedMonth = moment()
        .month(currentDate?.month - 1)
        .format("MMM");
      setDate(`${formattedMonth} ${currentDate?.year}`);
      chartCurrent.data = props.waterfallChart.data;
      const categoryAxis = chartCurrent.xAxes.push(
        new am4charts.CategoryAxis()
      );
      categoryAxis.dataFields.category = "key";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 30;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.events.on("datavalidated", (ev) => {
        const axis = ev.target;
        const end = Number(axis.positionToCategory(1));
        for (let current = 0; current <= end; current++) {
          const range = axis.axisRanges.create();
          range.grid.disabled = true;
          range.category = `${current}`;
          range.label.dataItem.text = `${ev.target.data[current].label}`;
          range['label']['maxWidth'] = 150;
          range['label']['wrap'] = true;
        }
      });
      chartCurrent.events.on("ready", function () {
        switch (true) {
          case isMobile:
            categoryAxis.zoomToIndexes(0, 2);
            break;
          case isTablet:
            categoryAxis.zoomToIndexes(0, 3);
            break;
          default:
            categoryAxis.zoomToIndexes(0, 4);
            break;
        }
        valueAxis.max = valueAxis.maxZoomed + 1;
      });
      const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;

      // Create series
      const series = chartCurrent.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = "endValue";
      series.dataFields.openValueY = "startValue";
      series.dataFields.categoryX = "key";
      series.fillOpacity = 0.8;
      series.columns.template.width = 70;
      series.columns.template.adapter.add("fill", (value, target, key) => {
        const dataItem = target.dataItem?.index || 0;
        const color = chartColorCode[chartCurrent.data[dataItem].color];
        return am4core.color(color);
      });
      series.columns.template.adapter.add(
        "strokeOpacity",
        (value, target, key) => {
          return 0;
        }
      );

      const labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.verticalCenter = "bottom";
      labelBullet.label.fillOpacity = 0.8;
      labelBullet.label.fontSize = 12;
      labelBullet.label.adapter.add("dy", (value, target) => {
        const dataItem = target.dataItem?.index || 0;
        let dy = 0;
        if (chartCurrent.data[dataItem].color === "blue") {
          dy = 25;
        }
        return dy;
      });
      labelBullet.label.adapter.add("text", (value, target) => {
        return setLabelBulletText(target, chartCurrent);
      });

      const columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 2;
      columnTemplate.strokeOpacity = 1;

      customizeScollbar(chartCurrent, true);
      return () => {
        chart.current?.dispose();
      };
    }
  }, [props.waterfallChart]);

  function setLabelBulletText(target, chartCurrent) {
    const dataItem = target.dataItem?.index || 0;
    let text: number | string = "0";
    const data = chartCurrent.data[dataItem];
    if (data.actualValue) {
      const actualValue = data.actualValue.toFixed(1);
      text = tenKFormatter(actualValue);
      text = `${text}`;
    }
    return text
  }

  const _renderMobileTablet = () => (
    <>
      {props.waterfallChart?.data?.length > 0 && (
        <div className={sMobile.waterFallChartContainer}>
          <div className={sMobile.topWaterfallChartDiv}>
            <div className={sMobile.topLeftWaterfallDiv}>
              <div>Waterfall Chart</div>

            </div>
          </div>
          <div className={sMobile.subTopWaterfall}>
            <span>{date}</span>
          </div>
          <div id="waterfallchartdiv" className={sMobile.waterFallChart} />
          <div className={sMobile.divBorder} />
          <div className={sMobile.topRightWaterfallDiv}>
            <div className={sMobile.boxlegendRed} />
            <span>Decrement</span>
            <div className={sMobile.boxlegendGreen} />
            <span>Increment</span>
            <div className={sMobile.boxlegendBlue} />
            <span>Value</span>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <Desktop>
        {props.waterfallChart?.data?.length > 0 && (
          <div className={s.waterFallChartContainer}>
            <div className={s.topWaterfallChartDiv}>
              <div className={s.topLeftWaterfallDiv}>
                <div>Waterfall Chart
                </div>
                <span
                  onClick={() => dispatch(openModalWaterfallChart())}
                >
                  See full info &gt;
                </span>
              </div>
              <div className={s.topRightWaterfallDiv}>
                <div className={s.boxlegendRed} />
                <span>Decrement</span>
                <div className={s.boxlegendGreen} />
                <span>Increment</span>
                <div className={s.boxlegendBlue} />
                <span>Value</span>
              </div>
            </div>
            <div className={s.subTopWaterfall}>
              <span>{date}</span>
            </div>
            <div id="waterfallchartdiv" className={s.waterFallChart} />
          </div>
        )}
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>

    </>
  );
};
export default WaterfallChartLevel2;
