import React, { useState } from 'react'
import s from './ScoreCardFolderList.module.css'
import { Col, Row } from 'antd';
import { MONTH_SHORT_TEXT } from '../../../constants/score-card.constants';
import UploadFileTable from '../UploadFileTable/UploadFileTable';
import ErrorStatusModal from '../ErrorStatusModal/ErrorStatusModal';

interface Iprop {
    folderList: {
        location: string;
        status: string;
        historyId: number
    }[],
    onOpenModal: (value: string) => void,
    currentDate: { month: number, year: number }
}

const buildStatus = (status: string) => {
    switch (status) {
        case 'TO DO':
            return 'rgba(253, 185, 36, 1)'
        case 'PROCESSING':
            return 'rgba(54, 133, 211, 1)'
        case 'SUCCESS':
            return 'rgba(0, 255, 93, 1)'
        case 'ERROR':
            return 'rgba(218, 34, 40, 1)'
        case 'OVERDUE': 
            return 'rgb(169,169,169)'
        default:
            return ''
    }
}

const ScoreCardFolderList = (props: Iprop) => {
    const { folderList, onOpenModal, currentDate } = props
    const [isShowErrorModal, setIsShowErrorModal] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<{ id: number; location: string } | null>(null);

    return (
        <>
            <h4 className={s.scorecardMenuHeader}><strong>Overdue</strong> (Files ought to be uploaded before {MONTH_SHORT_TEXT[currentDate.month]} 24, {currentDate.month === 12 ? currentDate.year + 1 : currentDate.year}.) </h4>
            <div className={s.scorecardContainer}>
                <Row justify="start" gutter={[16, 8]}>
                    {folderList.map((scoreCard, index) => {
                        return (
                            <Col span={4} key={index}>
                                <div
                                    className={s.scoreCardItem}
                                    onClick={() => onOpenModal(scoreCard.location)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && scoreCard.status !== 'ERROR') {
                                            onOpenModal(scoreCard.location);
                                        }
                                    }}
                                    tabIndex={0}
                                >
                                    <h5>{scoreCard.location}</h5>
                                    <span
                                        style={{ color: buildStatus(scoreCard.status) }}
                                        onClick={(e) => {
                                            if (scoreCard.status === 'ERROR') {
                                                e.stopPropagation();
                                                setSelectedRecord({ id: scoreCard.historyId, location: scoreCard.location });
                                                setIsShowErrorModal(true);
                                            }
                                        }}
                                    >
                                        {scoreCard.status}
                                    </span>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            {isShowErrorModal && selectedRecord !== null && (
                <ErrorStatusModal historyId={selectedRecord.id} location={selectedRecord.location} isShowErrorModal={isShowErrorModal} setIsShowErrorModal={setIsShowErrorModal} />
            )}
        </>
    )
}

export default ScoreCardFolderList