import { isNumber } from "lodash"
import { OPU_UPCS } from "../constants/opu.constants"
import { FINANCIAL_EXCELLENCE_LABEL, FINANCIAL_PERFORMANCE_LABEL, HSSE_PERFORMANCE_LABEL, OPERATIONAL_PERFORMANCE_LABEL, PLANT_PERFORMANCE_LABEL, PRODUCTION_GENERATION_LABEL, TAB_SCORE_CARD } from "../constants/score-card.constants"
import parse from 'html-react-parser';

export function renderSectionBySectionId(sectionId: number) {
  switch (sectionId) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      return "hssePeromanceData"
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      return "financialExcellenceData"
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      return "plantPerformanceData"
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      return "productionGenerationData"
    default:
      return "hssePeromanceData"
  }
}
export function renderNameSectionBySectionID(sectionId: number) {
  switch (sectionId) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      return HSSE_PERFORMANCE_LABEL
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      return FINANCIAL_EXCELLENCE_LABEL
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      return PLANT_PERFORMANCE_LABEL
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      return PRODUCTION_GENERATION_LABEL
    default:
      return HSSE_PERFORMANCE_LABEL
  }
}

export function renderScorecardNameBySectionLabel(label: string, selectedPersona: string) {
  switch (selectedPersona) {
    case OPU_UPCS.LMT:
      if (label === PRODUCTION_GENERATION_LABEL) {
        return OPERATIONAL_PERFORMANCE_LABEL;
      }
      else if (label === FINANCIAL_EXCELLENCE_LABEL) {
        return FINANCIAL_PERFORMANCE_LABEL;
      }
      break;
    case OPU_UPCS.MLNG:
      switch (label) {
        case HSSE_PERFORMANCE_LABEL:
          return "HSE";
        case FINANCIAL_EXCELLENCE_LABEL:
          return "Financial";
      }
      break;
  }
  return label;
}

// Utility function to sanitize numeric values
export const sanitizeNumber = (value) => {
  return isNumber(value) ? value : 0;
};

export const sanitizeStyleValue = (value, unit="") => {
  // Ensure the value is a number and is valid for use in CSS
  const sanitizedValue = parseFloat(value);
  return !isNaN(sanitizedValue) ? `${sanitizedValue}${unit??""}` : '0'+(unit??"");
}


export const parseToHtml = (value: string) => !!value ? parse(value) : value;