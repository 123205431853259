import "antd/dist/antd.css";

import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { ISummaries } from "../../../model/mft.model";
import s from "./MftExecSummaryComponent.module.css";

const MftExecSummaryComponent = () => {
  const summaryList: ISummaries = useSelector((state) =>
    get(state, "mft.summaryList", [])
  );

  function _renderSumarryLines() {
    if (!summaryList.summaries || summaryList.summaries.length === 0) {
      return (
          <div className={s.noResult}>
            <img src="/img/icon/no-result.svg" alt="No results" />
            <h2>No data available!</h2>
          </div>
          
      );
    }
    return summaryList.summaries?.map((item, idx) => (
      <li key={idx} className={s.executiveSummaryItem}>
        <div>{item?.category}</div>
        {item?.summaryLines.map((summary, index) => (
          <li key={index} className={s.executiveSummaryItem}>
            {summary} <br />
          </li>
        ))}
      </li>
    ));
  }

  return (
    <>
      <div className={s.mftLeft}>
        <div className={s.mftSectionTitle}>
          Executive <span>Summary</span>
        </div>
        <div className={s.mftLeftList}>
          <ul className={s.executiveSummaryList}>{_renderSumarryLines()}</ul>
        </div>
      </div>
    </>
  );
};
export default MftExecSummaryComponent;
