const VIEW_PAGE_ID = {
  VALUECHAIN: 2,
  SCORECARD: 1,
  MFT: 3,
  RISK_PROFILE: 5,
  ASSURANCE: 6,
}

const VIEW_PAGE_URL = {
  VALUECHAIN: "valuechain",
  SCORECARD: "scorecards",
  MFT: "mft-scorecard",
  RISK_PROFILE: "riskprofile",
  ASSURANCE: "assurance",
}

function setScorecardUrl(opuId, permissions) {
  let returnUrl = ""
  returnUrl = opuId === permissions.defaultView.opuId ? permissions.defaultView.viewUrl : "";
  permissions.allowedViews.forEach(item => {
    if (returnUrl === "" && opuId === item.opuId) {
      returnUrl = item.viewUrl
    }
  });

  return returnUrl
}

function setUrl(opuId, permissions) {
  let returnUrl = "";
  permissions.allowedViews.forEach(item => {
    if (opuId === item.opuId) {
      returnUrl = item.viewUrl
    }
  });

  if (returnUrl === "") {
    returnUrl = permissions.defaultView.viewUrl;
  }

  return returnUrl
}

function setOPU(opuId, viewPermissions) {
  const result =  ({
    [VIEW_PAGE_URL.VALUECHAIN]: setUrl(opuId, viewPermissions[VIEW_PAGE_ID.VALUECHAIN]),
    [VIEW_PAGE_URL.SCORECARD]: setScorecardUrl(opuId, viewPermissions[VIEW_PAGE_ID.SCORECARD]),
    [VIEW_PAGE_URL.MFT]: setUrl(opuId, viewPermissions[VIEW_PAGE_ID.MFT]),
  })
  if (viewPermissions[VIEW_PAGE_ID.ASSURANCE]) result[VIEW_PAGE_URL.ASSURANCE] = setUrl(opuId, viewPermissions[VIEW_PAGE_ID.ASSURANCE]);
  if(viewPermissions[VIEW_PAGE_ID.RISK_PROFILE]) result[VIEW_PAGE_URL.RISK_PROFILE] = setUrl(opuId, viewPermissions[VIEW_PAGE_ID.RISK_PROFILE])

  return result
}

function transformInitData(result) {
  const returnResult: any = [];
  result.claims.allowedOpus.forEach((item) => {
    returnResult.push({ ...item, viewUrl: { ...setOPU(item.opuId, result.claims.viewPermissions) } });
  });
  return returnResult;
}

export function transformViewPermission(results) {
  return transformInitData(results);
}
