import s from "../PLCListEquipmentComponent.module.css";
import v from "./GHGListEquipmentComponent.module.css";
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useEffect } from "react";
import { fetchSustainabilityDrilldownGT, fetchGhgData } from "../../../action/actionSustainability";
import { toFixedSustainabilityNumber } from "../../../utils/Number.utils";
import { Tooltip } from "antd";
import { Xwrapper } from "react-xarrows";
import Xarrow from "react-xarrows";
import queryString from "query-string";
import PiTagLegend from "../GHGTimestamp/GHGTimestampComponent";
import DelayedRender from "../../DelayedRender/DelayedRender";

const GHGListEquipmentGTComponent = (props) => {
  const {  location } = props;
  const params = queryString.parse(window.location.search);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const dispatch = useDispatch()

  const itemCoordinates = {
    "Northern": { X: 53, Y: 17 },
    "Eastern": { X: 81, Y: 17 },
    "Western" : {X: 4, Y: 58},
    "Southern": { X: 67, Y: 58 }
  }

  useEffect(() => {
    const params = `?location=${location}`
    dispatch(fetchSustainabilityDrilldownGT(params))
  },[]) 

  const GTModule = useSelector((state) =>
    get(state, 'sustainability.GTModule.result.listDrilldown', [])
  );

  const lastUpdated = useSelector((state) =>
    get(state, 'sustainability.GTModule', [])
  );

  const [expandedModules, setExpandedModules] = useState({
    "Northern": 1,
    "Eastern": 1,
    "Western": 1,
    "Southern": 1
  });

  const handleModuleClick = (data, index) => {
    setExpandedModules(prevState => ({
      ...prevState,
      [data]: prevState[data] === index ? null : index
    }));
  };

  function returnLine(){
    return (
      <>
      <Xarrow color={'white'} path={'grid'}
                start={"Northern"} 
                end={"18"} 
                startAnchor={{
                  position: 'left', 
                  offset: {y: -50}
                }}
                endAnchor={{
                  position: 'bottom',
                  offset: {},
                }}
                dashness={true}
                headSize={0}
                strokeWidth={2}
            /> 
    <Xarrow color={'white'} path={'grid'}
        start={"Northern"} 
        end={"19"} 
        startAnchor={{
          position: 'left', 
          offset: {y: -50}
        }}
        endAnchor={{
          position: 'top',
          offset: {},
        }}
        dashness={true}
        headSize={0}
        strokeWidth={2}
    /> 
    
    <Xarrow color={'white'} path={'grid'}
          start={"20"} 
          end={"21"} 
          SVGcanvasProps={{
            id: "20-21"
          }}
          startAnchor={{
            position: 'bottom', 
            offset: {}
          }}
          endAnchor={{
            position: 'top',
            offset: {x: -1},
          }}
          dashness={true}
          headSize={0}
          strokeWidth={2}
      />
    <DelayedRender delay={100}>
      <Xarrow color={'white'} path={'grid'}
          start={"Eastern"} 
          end={"20-21"} 
          startAnchor={{
            position: 'bottom', 
            offset: {x: -70}
          }}
          endAnchor={{
            position: 'middle',
            offset: {},
          }}
          dashness={true}
          headSize={0}
          strokeWidth={2}
      />
    </DelayedRender>

    <Xarrow color={'white'} path={'grid'}
        start={"22"} 
        end={"23"} 
        startAnchor={{
          position: 'bottom', 
          offset: {}
        }}
        endAnchor={{
          position: 'left',
          offset: {},
        }}
        dashness={true}
        headSize={0}
        strokeWidth={2}
        SVGcanvasProps={{
          id: "22-23"
        }}
    />
    <DelayedRender delay={100}>
      <Xarrow color={'white'} path={'grid'}
          start={"Western"} 
          end={"22-23"} 
          startAnchor={{
            position: 'right', 
            offset: {y: 20}
          }}
          endAnchor={{
            position: 'left',
            offset: {y: 43, x: 15},
          }}
          dashness={true}
          headSize={0}
          strokeWidth={2}
      />
    </DelayedRender>
    <Xarrow color={'white'} path={'grid'}
        start={"25"} 
        end={"24"} 
        startAnchor={{
          position: 'top', 
          offset: {}
        }}
        endAnchor={{
          position: 'right',
          offset: {},
        }}
        dashness={true}
        headSize={0}
        strokeWidth={2}
        SVGcanvasProps={{
          id: "24-25"
        }}
    /> 
   <DelayedRender delay={100}>
    <Xarrow color={'white'} path={'grid'}
          start={"Southern"} 
          end={"24-25"} 
          startAnchor={{
            position: 'left', 
            offset: {}
          }}
          endAnchor={{
            position: 'right',
            offset: {x: -15, y: -35.5},
          }}
          dashness={true}
          headSize={0}
          strokeWidth={2}
      />
   </DelayedRender>
      </>
    )
  }

  return <>
  <Xwrapper>
  {GTModule.map((item, index) => {
    return (
      <div className={s.equipmentItemContainer} onLoad={handleLoad} id={item.assetName} style={{ position: "absolute", top: `calc(0vh + ${itemCoordinates[item.assetName].Y}%)`, left: `calc(0vw + ${itemCoordinates[item.assetName].X}%)`}}>
        <div className={s.header}>{item.assetName}
        <span className={s.unit} title="tCO2e Per Hour">(tCO<sub>2</sub>e/h)</span>
        </div>

        {GTModule[index] && GTModule[index].listModule.map((location, index) => {
          const total = { value : 0 };

          function calculateTotal(num: number | string){
            const number = typeof num === 'string' ? parseFloat(num) : num;
            total.value += number;
          }

          return (
            <div
              key={index}
              className={s.equipmentModule}
              onClick={() => handleModuleClick(item.assetName, index)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModuleClick(item.assetName, index);
                }
              }}
              tabIndex={0}
            >
              {location.moduleName}
              <img
              src={expandedModules[item.assetName] === index ? 'img/icon/arrow-up-1.svg' : 'img/icon/arrow-down-1.svg'} 
              alt={expandedModules[item.assetName] === index ? 'Collapse' : 'Expand'} 
              style={{ float: 'right' }} 
              />


              { 
                expandedModules[item.assetName] === index && 
                [...location.listEquipment]
                  .sort((a, b) => Number(b.value) - Number(a.value))
                  .map((key, index, array) => {
                    calculateTotal(Number(key.value).toFixed(3));
                    return (
                      <>
                        <div className={s.equipmentList}>
                          <div className={s.equipmentName}>{key.equipmentName}</div>
                          <div className={s.data}>{Number(key.value).toFixed(3)}</div>
                        </div>
                        {/* Display total if exhausted equipment for the module */}
                        {index === array.length - 1 && (
                          <div className={s.equipmentList}>
                            <div className={`${s.equipmentName} ${s.font}`}>Total</div>
                            <div className={`${s.data} ${s.font}`}>{toFixedSustainabilityNumber(total.value, 3)}</div>
                          </div>
                        )}
                      </>
                    );
                  })
              }
              
            </div>
          );
          })
          }
      </div>
              );
    })}
    {isLoaded && returnLine()}
      </Xwrapper>
    <PiTagLegend param={params} ghgData={lastUpdated} />
  </>
};

export default GHGListEquipmentGTComponent;
