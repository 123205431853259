import { Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { fetchCalloutData, fetchCalloutDataSec, mapPointViewDetail } from "../../action/actionMap";
import { formatNumber } from "../../utils/Number.utils";
import { formatUnit } from "../../utils/unit.utils";
import { CALLOUT_TYPE } from "../../constants/location.constants";
import { LOCATION_ID, LOCATION_NAME } from "../../constants/map.constants";
import s from "../Map/map.module.css";
import { sanitizeNumber, sanitizeStyleValue } from "../../utils/common.utils";
const MapPointComponent = (props: any) => {
  let location = props.data;
  const allowedCallouts = [21, 23, 24, 26, 30, 31, 32, 33, 34, 35, 47, 48, 49, 57, 58, 59];
  const dispatch = useDispatch();
  const selectedPoint = useSelector((state) =>
    get(state, 'map.selectedPoint', null)
  );
  const mapData = useSelector((state) =>
    get(state, 'map.mapData.result', null)
  );
  // Click point
  function handleViewDetail() {
    if (location?.callout) {
      dispatch(mapPointViewDetail(location));
      if ([CALLOUT_TYPE.UKUG, CALLOUT_TYPE.RGT, CALLOUT_TYPE.PGU, CALLOUT_TYPE.LNG_DELIVERY].includes(location.calloutTypeId) || allowedCallouts.includes(location?.callout)) {
        dispatch(fetchCalloutDataSec(location?.callout));
      } else {
        dispatch(fetchCalloutData(location?.callout))
      }
    }
  }

  function _renderPointGroup() {
    const pointGroupClassName = (location.locationId === selectedPoint?.locationId 
                                && location.locationName === selectedPoint?.locationName) ? 
      `${s.pointGroupDot} ${s.selectedDot}` : s.pointGroupDot;
    return (
      <div 
        className={pointGroupClassName}
        onClick={() => handleViewDetail()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleViewDetail();
          }
        }}
        tabIndex={0}
      >
        <span></span>
      </div>
    )
  }

  function _renderDataPoint() {
    if (location?.locationName === LOCATION_NAME.SCORE) {
      // return <div className={`${s.pointGroupDot} ${s.scoreDot}`}></div>;
      return
    } else {
      return _renderPointGroup();
    }
  }

  function pointGroupInfoClassName() {
    if (location?.locationName === LOCATION_NAME.SCORE) {
      return `${s.pointGroupInfo} ${s.scorePoint}`;
    } else if (location?.locationName === LOCATION_NAME.PLENTONG) {
      return `${s.pointGroupInfo} ${s.plentongPoint}`;
    } else if (location?.locationName === LOCATION_NAME.TTM) {
      return `${s.pointGroupInfo} ${s.ttmPoint}`;
    } else if (location?.locationName === LOCATION_NAME.UKUG) {
      return `${s.pointGroupInfo} ${s.ukugPoint}`;
    } else if (mapData?.locationName === LOCATION_NAME.RGPT) {
      return `${s.pointGroupInfo} ${s.rgtp}`;
    } else {
      return s.pointGroupInfo;
    }
  }

  function locationName() {
    if ([LOCATION_ID.RGPT, LOCATION_ID.RGTSU].includes(mapData.locationId)) {
      if (
        [
          LOCATION_ID.RGPT_LNG_STORAGE_SECONDARY,
          LOCATION_ID.RGPT_LNG_SEND_OUT_SECONDARY,
          LOCATION_ID.RGTSU_LNG_STORAGE_SECONDARY,
          LOCATION_ID.RGTSU_LNG_SEND_OUT_SECONDARY,
        ].includes(location.locationId)
      ) {
        return <div style={{ height: '15px' }}></div>;
      }
    }
    return location.locationName;
  }

  return (
    <div className={`${s.pointGroupWrap} ${location?.position ? s[location?.position] : s.Left} ${s[location.heartPulse]}`} style={{ top: sanitizeStyleValue(sanitizeNumber(location.Y),"px"), left: sanitizeStyleValue(sanitizeNumber(location.X), "px") }}>
      <div className={s.pointGroup}>

        {_renderDataPoint()}
        <div className={pointGroupInfoClassName()}>
          <div className={s.pointGroupInfoTop}>
            <span>{locationName()}</span>
          </div>
          <div className={s.pointGroupInfoBody}>
            {
              location?.locationId === 99 && (
                <div className={s.pointGroupInfoBodyText}>
                  <b>11,570</b>
                  <span>m<sup>3</sup></span>
                  <b>482.1</b>
                  <span>m<sup>3</sup>/hr</span>
                </div>
              )
            }
            {
              location?.parameters.map((param: any, idx: any) => {
                if (location?.locationId === 99) {
                  return null;
                }
                return (
                  <div key={'y' + idx}>
                    {
                      param?.displayLabel &&
                      <div className={s.mb5px}><b className={s.dLabel}>{param?.displayLabel}</b></div>
                    }
                    <div className={s.pointGroupInfoBodyText} key={'x' + idx}>
                    <b>
                      {param?.value == null || param?.value == 'N/A'
                        ? '--'
                        : isNaN(param?.value)
                        ? param?.value
                        : param?.unit === 'BCe' && Number(param?.value) % 1 === 0
                        ? Number(param?.value).toFixed(0)
                        : Number(param?.value).toLocaleString('en-US')}
                    </b>
                      {formatUnit(param?.unit)}
                    </div>

                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default MapPointComponent;