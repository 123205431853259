import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getAvailablePeriod, getRiskTop, getRiskKRICallout, getRiskMitigationCallout, getGasBusinessFLA, getLNGAFLA, getLMTFLA, getGCOFLA, getGPFLA } from '../services/risk.service';
import { take } from 'lodash';

interface RiskAction extends Action {
  data: string | any;
  payload: {} | any;
}

function* fetchRiskTop(action?: RiskAction) {
  try {
    const response = yield call(getRiskTop, action?.payload)
    yield put({type: types.GET_RISK_TOP_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_TOP_FAIL,
      message: error.message,
    });
  }
}

function* fetchAvailablePeriod(action?: RiskAction) {
  try {
    const response = yield call(getAvailablePeriod, action?.payload)
    yield put({type: types.GET_RISK_AVAILABLE_PERIOD_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_AVAILABLE_PERIOD_FAIL,
      message: error.message,
    });
  }
}

function* fetchRiskKRICallout(action?: RiskAction) {
  try {
    const response = yield call(getRiskKRICallout, action?.payload)
    yield put({type: types.GET_RISK_KRI_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_KRI_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchRiskMitigationCallout(action?: RiskAction) {
  try {
    const response = yield call(getRiskMitigationCallout, action?.payload)
    yield put({type: types.GET_RISK_MITIGATION_CALLOUT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_RISK_MITIGATION_CALLOUT_FAIL,
      message: error.message,
    });
  }
}

function* fetchGasBusinessFLA(action?: RiskAction) {
  try {
    const response = yield call(getGasBusinessFLA, action?.payload)
    yield put({type: types.GET_GAS_BUSINESS_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GAS_BUSINESS_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLNGAFLA(action?: RiskAction) {
  try {
    const response = yield call(getLNGAFLA, action?.payload)
    yield put({type: types.GET_LNGA_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LNGA_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGPFLA(action?: RiskAction) {
  try {
    const response = yield call(getGPFLA, action?.payload)
    yield put({type: types.GET_GP_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GP_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchLMTFLA(action?: RiskAction) {
  try {
    const response = yield call(getLMTFLA, action?.payload)
    yield put({type: types.GET_LMT_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_LMT_FLA_FAIL,
      message: error.message,
    });
  }
}

function* fetchGCOFLA(action?: RiskAction) {
  try {
    const response = yield call(getGCOFLA, action?.payload)
    yield put({type: types.GET_GCO_FLA_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_GCO_FLA_FAIL,
      message: error.message,
    });
  }
}

export function* workerFetchRiskData() {
  yield takeLatest(types.GET_RISK_TOP_REQUESTED, fetchRiskTop);
  yield takeLatest(types.GET_RISK_AVAILABLE_PERIOD_REQUESTED, fetchAvailablePeriod);
  yield takeLatest(types.GET_RISK_KRI_CALLOUT_REQUESTED, fetchRiskKRICallout);
  yield takeLatest(types.GET_RISK_MITIGATION_CALLOUT_REQUESTED, fetchRiskMitigationCallout);
  yield takeLatest(types.GET_GAS_BUSINESS_FLA_REQUESTED, fetchGasBusinessFLA);
  yield takeLatest(types.GET_LNGA_FLA_REQUESTED, fetchLNGAFLA);
  yield takeLatest(types.GET_GP_FLA_REQUESTED, fetchGPFLA);
  yield takeLatest(types.GET_LMT_FLA_REQUESTED, fetchLMTFLA);
  yield takeLatest(types.GET_GCO_FLA_REQUESTED, fetchGCOFLA);
}