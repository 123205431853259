import { Action } from "redux";

import * as types from "../action/actionType";

export const initialUser = {
  username: 'test',
  selectedPersona: '',
  personaSelection: [],
  opusUserAccess: [],
  adminsUserAccess: [],
  isRequesting: false
};

interface UserAction extends Action {
  payload: string | any;
}

export function userReducer(state = initialUser, action: UserAction) {
  switch (action.type) {
    case types.UPDATE_USERNAME: {
      // always return a new object in Redux
      return {
        ...state,
        username: action.payload,
      };
    }
    case types.USER_FETCH_SUCCEEDED: {
      return {
        ...state,
        username: action.payload?.username
      }
    }
    case types.USER_FETCH_IMAGE_SUCCEEED: {
      return {
        ...state,
        image: action.payload
      };
    }
    case types.USER_FETCH_PROPERTIES_SUCCEEDED: {
      return {
        ...state,
        user_properties: action.payload
      };
    }
    case types.USER_SET_PERSONA_SELECTION: {
      return {
        ...state,
        personaSelection: action.payload
      };
    }
    case types.USER_SET_SELECTED_PERSONA: {
      return {
        ...state,
        selectedPersona: action.payload
      };
    }
    case types.USER_SET_SELECTED_PERSONA_VIEWURL: {
      return {
        ...state,
        selectedPersonaViewUrl: action.payload
      };
    }
    case types.USER_ACCESS_OPUS_LOADED: {
      return {
        ...state,
        opusUserAccess: action.payload,
      };
    }
    case types.USER_ACCESS_ADMINS_LOADED: {
      return {
        ...state,
        adminsUserAccess: action.payload,
      };
    }
    case types.USER_ACCESS_SENDING: {
      return {
        ...state,
        isRequesting: true
      };
    }
    case types.USER_ACCESS_SENT: {
      return {
        ...state,
        responseAlert: action.payload,
        isRequesting: false
      };
    }
  }
  return state;
}