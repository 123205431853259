import { Action } from "redux";
import * as types from "../action/actionType";
import {
  FINANCIAL_EXCELLENCE_LABEL,
  HSSE_PERFORMANCE_LABEL,
  PLANT_PERFORMANCE_LABEL,
  PRODUCTION_GENERATION_LABEL,
  TAB_SCORE_CARD,
} from "../constants/score-card.constants";
export const initialState = {
  periodsList: [],
  folderList: [],
  historyUploadList: [],
  historyErrorList: [],
  result: false,
  resultMessage: "",
  uploadMessage: "",
  isUploadFileSuccess: false,
  isUploadLoading: false,
  executiveSummary: [],
  strategicInitiatives: {},
  bigCardInfor: [],
  smallCardInfor: [],
  primaryCardInforDetail: [],
  breakDownCardInfor: [],
  cargoDeliveryData: {},
  breakDownCardDetailLevel3: {
    data: [],
    breakdownCards: [],
    cardActive: {},
  },
  sectionIdActive: TAB_SCORE_CARD.HSSE_PERFORMANCE,
  revenueChart: {},
  waterfallChart: [],
  currentTab: "",
  revenueChartLvl3: {},
  waterfallChartLvl3: [],
  secondaryCardDetail: [],
  lmtChartData: {},
  lmtCountryChartData: {},
  lmtCustomerChartData: {},
  lmtCountryData: [],
  lmtContractData: {},
  lmtContractDetailTable: {},
  selectedLmtCountry: [],
  selectedLmtCustomer: [],
  selectedLmtSource: [],
  selectedScorecard: null,
  selectedTabIndex: 0,
  keyListDropDownMiniCardLv2: "",
  levelScoreCard: 1,
  keyScoreCard: "",
  currentDate: {
    month: NaN,
    year: NaN,
  },
  units: [],
  unitSelected: "",
  isVisibleModalWaterfallChart: false,
  isOpenScorecardDetailsModal: false,
  isOpenScorecardDetailsChartModal: false,
  isOpenScorecardSecondaryCardModel: false,
  isOpenScorecardLmtChartModal: false,
  saleVolumeData: null,
  isDeleteFileUpload: false,
  deleteFileUploadMessage: "",
  excelUrl: "",
};

interface UserAction extends Action {
  data: string | any;
  payload: string | any;
}

export function scoreCardReducer(state = initialState, action: UserAction) {
  switch (action.type) {
    case types.SCORE_CARD_EXECUTIVE_SUMMARY_SUCCEEDED: {
      return {
        ...state,
        executiveSummary: action.payload?.summaries,
      };
    }
    case types.SCORE_CARD_GNE_TAB_BIG_CARD_SUCCEEDED: {
      let property = {};
      switch (action.payload?.sectionName) {
        case HSSE_PERFORMANCE_LABEL:
          property = {
            hssePeromanceData: action.payload,
          };
          break;
        case FINANCIAL_EXCELLENCE_LABEL:
          property = {
            financialExcellenceData: action.payload,
          };
          break;
        case PLANT_PERFORMANCE_LABEL:
          property = {
            plantPerformanceData: action.payload,
          };
          break;
        case PRODUCTION_GENERATION_LABEL:
          property = {
            productionGenerationData: action.payload,
          };
          break;
        default:
          property = {
            hssePeromanceData: action.payload,
          };
          break;
      }
      return {
        ...state,
        ...property,
      };
    }
    case types.SCORE_CARD_GNE_TAB_SMALL_CARD_SUCCEEDED: {
      return {
        ...state,
        smallCardInfor: action.payload,
      };
    }
    case types.SCORE_CARD_UPDATE_LEVEL_AND_KEY: {
      return {
        ...state,
        levelScoreCard: action.payload?.level,
        keyScoreCard: action.payload?.key,
        sectionIdActive: action.payload?.tab,
        currentTab: action.payload?.tab,
        units: action.payload?.units,
        unitSelected: action.payload?.unitSelected,
      };
    }
    //  PRIMARY CARD & BREAKDOWN level 2
    case types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_SUCCEEDED:
      return {
        ...state,
        primaryCardInforDetail: action.payload,
      };

    case types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_SUCCEEDED:
      return {
        ...state,
        breakDownCardInfor: action.payload,
      };
    // Change sectionid active
    case types.SCORE_CARD_GNE_TAB_CHANGE_SECTIONID_ACTIVE:
      return {
        ...state,
        sectionIdActive: action.payload,
      };
    // Revenue Chart
    case types.SCORE_CARD_REVENUE_CHART_REQUESTED:
      return {
        ...state,
        currentTab: action.payload?.tabActive,
        revenueChart: {},
      };
    case types.SCORE_CARD_REVENUE_CHART_SUCCEEDED:
      return {
        ...state,
        revenueChart: action.payload,
      };
    // Waterfall Chart
    case types.SCORE_CARD_WATERFALL_CHART_REQUESTED:
      return {
        ...state,
        waterfallChart: [],
      };
    case types.SCORE_CARD_WATERFALL_CHART_SUCCEEDED:
      return {
        ...state,
        waterfallChart: action.payload,
      };
    case types.SCORE_CARD_WATERFALL_CHART_FAILED:
      return {
        ...state,
        waterfallChart: [],
      };
    // breakdowncard modal level 3
    case types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_SUCCEEDED:
      return {
        ...state,
        breakDownCardDetailLevel3: {
          ...state.breakDownCardDetailLevel3,
          data: action.payload,
        },
      };

    case types.SCORE_CARD_GNE_TAB_BREAKDOWN_ACTIVE_LV3:
      return {
        ...state,
        breakDownCardDetailLevel3: {
          ...state.breakDownCardDetailLevel3,
          cardActive: action.payload,
        },
      };

    case types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_SUCCEEDED:
      return {
        ...state,
        breakDownCardDetailLevel3: {
          ...state.breakDownCardDetailLevel3,
          breakdownCards: action.payload,
        },
      };
    case types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_UPDATE:
      return {
        ...state,
        breakDownCardDetailLevel3: {
          ...state.breakDownCardDetailLevel3,
          breakdownCards: action.payload,
        },
      };
    // score card menu
    case types.SCORE_CARD_MENU_LIST_SUCCEEDED:
      return {
        ...state,
        folderList: action.payload,
      };

    // list history upload
    case types.SCORE_CARD_LIST_HISTORY_UPLOAD_SUCCEEDED:
      return {
        ...state,
        historyUploadList: action.payload,
      };

    // list history error
    case types.SCORECARD_LIST_HISTORY_ERROR_SUCCEEDED:
      return {
        ...state,
        historyErrorList: action.payload,
      };
    case types.SCORECARD_LIST_HISTORY_ERROR_FAILED:
      return {
        ...state,
        historyErrorList: [],
      };

    // revenue chart level 3
    case types.SCORE_CARD_REVENUE_CHART_LVL3_SUCCEEDED:
      return {
        ...state,
        revenueChartLvl3: action.payload,
      };

    case types.SCORE_CARD_GNE_PERIODS_LIST_SUCCEEDED:
      return {
        ...state,
        periodsList: action.payload,
      };

    case types.SCORE_CARD_WATERFALL_CHART_LVL3_SUCCEEDED:
      return {
        ...state,
        waterfallChartLvl3: action.payload,
      };

    case types.SCORE_CARD_WATERFALL_CHART_LVL3_CLEAR:
      return {
        ...state,
        waterfallChartLvl3: [],
      };

    case types.SCORE_CARD_SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.payload,
      };

    case types.SCORE_CARD_GNE_LIST_MINICARD_DROPDOWN_LVL2:
      return {
        ...state,
        keyListDropDownMiniCardLv2: action.payload.key,
        levelScoreCard: action.payload?.level,
      };
    case types.SCORECARD_STRATEGIC_SUCCEEDED:
      return {
        ...state,
        strategicInitiatives: action?.payload,
      };

    case types.GET_CARGO_DELIVERY_SUCCESS: {
      return {
        ...state,
        cargoDeliveryData: action.payload,
      };
    }

    // Secondary Card Detail
    case types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_SUCCEEDED:
      return {
        ...state,
        secondaryCardDetail: action.payload,
      };
    case types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_FAILED:
      return {
        ...state,
        secondaryCardDetail: [],
      };
    case types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_SUCCEEDED:
      return {
        ...state,
        secondaryCardDetail: action.payload.result,
      };
    case types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_FAILED:
      return {
        ...state,
        secondaryCardDetail: [],
      };
    case types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED:
      return {
        ...state,
        executiveSummary: action.payload,
      };
    case types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED:
      return {
        ...state,
        executiveSummary: [],
      };
    case types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_SUCCEEDED:
      return {
        ...state,
        secondaryCardDetail: action.payload.result,
      };
    case types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_FAILED:
      return {
        ...state,
        secondaryCardDetail: [],
      };
    case types.SCORECARD_GNE_TAB_PERFORMANCE_SECONDARY_CARD_SUCCEEDED:
      return {
        ...state,
        secondaryCardDetail: action.payload.result,
      };
    case types.SCORECARD_GNE_TAB_PERFORMANCE_SECONDARY_CARD_FAILED:
      return {
        ...state,
        secondaryCardDetail: [],
      };

    //general
    case types.SCORECARD_LMT_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        lmtChartData: action.payload,
      };
    case types.SCORECARD_LMT_CHART_DATA_FAILED:
      return {
        ...state,
        lmtChartData: null,
      };
    //country
    case types.SCORECARD_LMT_COUNTRY_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        lmtCountryChartData: action.payload,
      };
    case types.SCORECARD_LMT_COUNTRY_CHART_DATA_FAILED:
      return {
        ...state,
        lmtCountryChartData: null,
      };
    //customer
    case types.SCORECARD_LMT_CUSTOMER_CHART_DATA_SUCCEEDED:
      return {
        ...state,
        lmtCustomerChartData: action.payload,
      };
    case types.SCORECARD_LMT_COUNTRY_CHART_DATA_FAILED:
      return {
        ...state,
        lmtCustomerChartData: null,
      };
    case types.SCORECARD_LMT_SALES_COUNTRY_DATA_SUCCEEDED:
      return {
        ...state,
        lmtCountryData: action.payload,
      };
    case types.SCORECARD_LMT_SALES_COUNTRY_DATA_FAILED:
      return {
        ...state,
        lmtCountryData: null,
      };
    case types.SCORECARD_LMT_CONTRACT_DETAIL_SUCCEEDED:
      return {
        ...state,
        lmtContractData: action.payload,
      };
    case types.SCORECARD_LMT_CONTRACT_DETAIL_FAILED:
      return {
        ...state,
        lmtContractData: null,
      };
    //sale volume
    case types.SCORECARD_LMT_SALE_VOLUME_SUCCEEDED:
      return {
        ...state,
        saleVolumeData: action.payload,
      };
    case types.SCORECARD_LMT_SALE_VOLUME_FAILED:
      return {
        ...state,
        saleVolumeData: null,
      };
    case types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_SUCCEEDED:
      return {
        ...state,
        lmtContractDetailTable: action.payload,
      };
    case types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_FAILED:
      return {
        ...state,
        lmtContractDetailTable: null,
      };

    //validate
    case types.VALIDATE_SCORECARD_FILE_REQUESTED: {
      return {
        ...state,
        result: false,
      };
    }
    case types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_TRUE: {
      return {
        ...state,
        result: action.payload,
      };
    }
    case types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL: {
      return {
        ...state,
        resultMessage: action.payload,
      };
    }
    case types.REMOVE_VALIDATE_SCORECARD_MESSAGE: {
      return {
        ...state,
        resultMessage: "",
      };
    }

    case types.REMOVE_VALIDATE_SCORECARD_FILE_REQUESTED: {
      return {
        ...state,
        result: false,
      };
    }
    case types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED: {
      return {
        ...state,
        result: true,
      };
    }
    case types.VALIDATE_SCORECARD_FILE_REQUESTED_FAILED: {
      return {
        ...state,
        result: false,
      };
    }
    //upload scorecard file
    case types.UPLOAD_SCORECARD_FILE_REQUESTED_SUCCEEDED:
      return {
        ...state,
        isUploadLoading: false,
        isUploadFileSuccess: true,
        uploadMessage: !action.payload && "",
      };
    case types.UPLOAD_SCORECARD_FILE_REQUESTED_FAILED:
      return {
        ...state,
        isUploadLoading: false,
        isUploadFileSuccess: false,
        uploadMessage: action.payload,
      };
    case types.UPLOAD_SCORECARD_FILE_REQUESTED:
      return {
        ...state,
        uploadMessage: "",
        isUploadLoading: true,
        isUploadFileSuccess: false,
      };
    //remove upload file message
    case types.REMVOE_UPLOAD_FILE_MESSAGE:
      return {
        ...state,
        uploadMessage: "",
      };
    case types.RESET_UPLOAD_FILE_SUCCESS_STATUS:
      return {
        ...state,
        isUploadFileSuccess: false,
        uploadMessage: "",
      };

    // Manage selection
    case types.SCORECARD_LMT_SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedLmtCountry: action.data,
      };
    case types.SCORECARD_LMT_SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedLmtCustomer: action.data,
      };
    // Manage selection
    case types.SCORECARD_LMT_SET_SELECTED_SOURCE:
      return {
        ...state,
        selectedLmtSource: action.data,
      };

    // Modal
    case types.OPEN_MODAL_WATERFALL_CHART:
      return {
        ...state,
        isVisibleModalWaterfallChart: true,
      };
    case types.CLOSE_MODAL_WATERFALL_CHART:
      return {
        ...state,
        isVisibleModalWaterfallChart: false,
      };
    case types.OPEN_SCORECARD_DETAILS_MODAL: {
      return {
        ...state,
        isOpenScorecardDetailsModal: true,
      };
    }
    case types.CLOSE_SCORECARD_DETAILS_MODAL: {
      return {
        ...state,
        isOpenScorecardDetailsModal: false,
      };
    }
    case types.OPEN_SCORECARD_DETAILS_CHART_MODAL: {
      return {
        ...state,
        isOpenScorecardDetailsChartModal: true,
      };
    }
    case types.CLOSE_SCORECARD_DETAILS_CHART_MODAL: {
      return {
        ...state,
        isOpenScorecardDetailsChartModal: false,
      };
    }
    case types.OPEN_SCORECARD_SECONDARY_CARD_MODAL: {
      return {
        ...state,
        selectedScorecard: action?.data,
        isOpenScorecardSecondaryCardModel: true,
      };
    }
    case types.OPEN_SCORECARD_SECONDARY_CARD_TAB_MODAL: {
      return {
        ...state,
        selectedTabIndex: action?.payload,
        isOpenScorecardSecondaryCardModel: true,
      };
    }
    case types.CLOSE_SCORECARD_SECONDARY_CARD_MODAL: {
      return {
        ...state,
        selectedScorecard: null,
        secondaryCardDetail: [],
        selectedTabIndex: 0,
        isOpenScorecardSecondaryCardModel: false,
      };
    }
    case types.OPEN_SCORECARD_LMT_CHART_MODAL: {
      return {
        ...state,
        isOpenScorecardLmtChartModal: true,
      };
    }
    case types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_SUCCEEDED: {
      return {
        ...state,
        isDeleteFileUpload: action.payload.result,
        deleteFileUploadMessage: action.payload.resultMessage,
      };
    }
    case types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED: {
      return {
        ...state,
        excelUrl: action.payload,
      };
    }
    case types.SCORE_CARD_GNE_CLEAR_PREVIEW_UPLOAD_FILE_FAILED: {
      return {
        ...state,
        excelUrl: "",
      };
    }
    case types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_FAILED: {
      return {
        ...state,
        isDeleteFileUpload: false,
        deleteFileUploadMessage: "",
      };
    }
    case types.SCORE_CARD_GNE_CLEAR_UPLOAD_FILE_MESSAGE: {
      return {
        ...state,
        isDeleteFileUpload: false,
        deleteFileUploadMessage: "",
      };
    }
    case types.CLOSE_SCORECARD_LMT_CHART_MODAL: {
      return {
        ...state,
        lmtChartData: null,
        lmtContracData: null,
        lmtCountryData: [],
        lmtContractDetailTable: [],
        selectedLmtCountry: [],
        selectedLmtCustomer: [],
        selectedLmtSource: [],
        isOpenScorecardLmtChartModal: false,
      };
    }
  }

  return state;
}
