import { LABEL_HEADER_MOBILE, URL_SIDE_BAR } from '../constants/map.constants';
export function getHeaderLabel (activeTab: string) {
    switch (activeTab) {
        case URL_SIDE_BAR.MAP:
            return LABEL_HEADER_MOBILE.MALAYSIA_MAP;
        case URL_SIDE_BAR.VALUE_CHAIN:
            return LABEL_HEADER_MOBILE.VALUE_CHAIN;
        case URL_SIDE_BAR.SCORECARDS:
            return LABEL_HEADER_MOBILE.SCORECARDS;
        case URL_SIDE_BAR.SUSTAINABILITY:
            return LABEL_HEADER_MOBILE.SUSTAINABILITY;
        default:
            break;
    }
}

export function isRiskMngmtAccessible(personaSelection, path?: string){
    return !!personaSelection?.some && !!personaSelection?.some(perSelection => 
      perSelection?.viewUrl && 
      (Object.values(perSelection.viewUrl).includes(path) || !path) &&
      ( Object.values(perSelection.viewUrl).includes(path) || Object.values(perSelection.viewUrl).includes(URL_SIDE_BAR.RISK_PROFILE) || Object.values(perSelection.viewUrl).includes(URL_SIDE_BAR.ASSURANCE))
    )
  }