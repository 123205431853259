import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import moment from 'moment';

const chartColorCode = {
  blue: '#8FC8F8',
  yellow: '#DFEB98',
  green: '#50A4B9',
  purple: '#9E94DB',
};

const frequencyFormat = {
  '': 'minute',
  '1hour': 'minute',
  '8hour': 'hour',
  daily: 'hour',
  weekly: 'day',
  biweekly: 'day',
};

export const CHART_COLOR_PALLATE = {
  1: '#847FE0',
  2: '#50A4B9',
  3: '#00A19C',
  4: '#009DDC',
  5: '#E75552',
};

let colorRunnerIdx = 0;
export function getColorPallete() {
  if (colorRunnerIdx > 4) {
    colorRunnerIdx = 0;
  }
  colorRunnerIdx++;
  return CHART_COLOR_PALLATE[colorRunnerIdx];
}

export function customizeScollbar(
  chart: am4charts.XYChart,
  disableGrip: boolean = false
) {
  chart.scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX.minHeight = 4;
  chart.scrollbarX.thumb.background.fill = am4core.color('#00A19C');
  chart.scrollbarX.background.fill = am4core.color('#0C394A');
  chart.scrollbarX.parent = chart.bottomAxesContainer;
  chart.scrollbarX.startGrip.disabled = disableGrip;
  chart.scrollbarX.endGrip.disabled = disableGrip;
}

export function customizeSlider(grip: any) {
  grip.background.fill = am4core.color('#00A19C');
  grip.background.stroke = am4core.color('#00A19C');
  grip.height = 14;
  grip.width = 14;
  grip.icon.disabled = true;
}

export function populateLineChartData(data) {
  let chartArrayLength: any = [];
  for (
    let didx = 0;
    didx < data?.tabsInfo?.secondaryInfo?.dataFields.length;
    didx++
  ) {
    let chartDataLength =
      data?.tabsInfo?.secondaryInfo?.dataFields[didx]?.chartData.length;
    if (chartDataLength !== undefined) {
      chartArrayLength.push(chartDataLength);
    }
  }

  let highestArrayLength = Math.max(...chartArrayLength);

  let chartData: {
    date: any;
    tooltipLabel: string;
    series1: number;
    series2: number;
    series3: number;
    series4: number;
    unit1: string;
    unit2: string;
    unit3: string;
    unit4: string;
  }[] = [];
  for (let index = 0; index < highestArrayLength; index++) {
    chartData.push({
      date: null,
      tooltipLabel: '',
      series1: 0,
      series2: 0,
      series3: 0,
      series4: 0,
      unit1: '',
      unit2: '',
      unit3: '',
      unit4: '',
    });
  }
  return chartData;
}

export function customizeColor(chart: am4charts.XYChart, data) {
  let seriesColor: any = [];
  for (
    let index = 0;
    index < data?.tabsInfo?.secondaryInfo?.dataFields.length;
    index++
  ) {
    seriesColor.push(
      am4core.color(
        chartColorCode[
          data?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartColor
        ]
      )
    );
  }
  chart.colors.list = seriesColor;
}

export function customizeXGrid(chart: am4charts.XYChart, frequency) {
  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.renderer.labels.template.fontSize = 10;
  dateAxis.renderer.ticks.template.disabled = false;
  dateAxis.renderer.ticks.template.stroke = am4core.color('#fff');
  dateAxis.renderer.labels.template.fill = am4core.color('#fff');
  dateAxis.renderer.labels.template.fontSize = 10;
  dateAxis.cursorTooltipEnabled = false;
  dateAxis.baseInterval = { timeUnit: frequencyFormat[frequency], count: 1 };
}

export function setTooltip(isTooltip: boolean, tooltipHtml: string, series) {
  if (!isTooltip) {
    series.tooltipHTML = tooltipHtml;
    let tooltip = series.tooltip;
    if (tooltip) {
      tooltip.getFillFromObject = false;
      tooltip.background.fill = am4core.color('#000000');
      tooltip.background.strokeOpacity = 0;
    }
  }
}

export function createAxisAndSeries(
  chartCurrent: am4charts.XYChart,
  name,
  opposite,
  seriesIndex,
  toolTipString,
  isAxesPopulate,
  valueAxis,
  datas
) {
  let seriesNo = seriesIndex + 1;
  valueAxis.renderer.opposite = opposite;
  let series = chartCurrent.series.push(new am4charts.LineSeries());
  series.dataFields.dateX = 'date';
  series.dataFields.valueY = `series${seriesNo}`;
  series.yAxis = valueAxis;
  valueAxis.renderer.minWidth = 35;
  if (
    !isAxesPopulate[
      datas?.tabsInfo?.secondaryInfo?.dataFields[seriesIndex]?.chartAxesPosition
    ]
  ) {
    valueAxis.title.text =
      datas?.tabsInfo?.secondaryInfo?.dataFields[
        seriesIndex
      ].chartData[0]?.tooltipUnit;
    valueAxis.title.rotation = 0;
    valueAxis.title.align = 'center';
    valueAxis.title.valign = 'top';
    valueAxis.title.dy = -17;
    valueAxis.title.dx = 5;
    valueAxis.title.fontSize = 10;
    valueAxis.layout = 'absolute';
    valueAxis.cursorTooltipEnabled = false;
  }

  valueAxis.renderer.labels.template.fontSize = 11;
  if (
    datas?.tabsInfo?.secondaryInfo?.dataFields[seriesIndex]?.chartType ===
    'area'
  ) {
    valueAxis.baseValue = -1000;
    series.fillOpacity = 0.3;
  }
  if (
    datas?.tabsInfo?.secondaryInfo?.dataFields[seriesIndex]?.chartType === 'dot'
  ) {
    series.strokeDasharray = '3,3';
  }
  series.name = name;
  series.fontSize = 11;
  series.tooltipPosition = 'fixed';
  if (datas?.tabsInfo?.secondaryInfo?.dataFields.length === seriesNo) {
    let tooltip = series.tooltip || new am4core.Tooltip();
    tooltip.getFillFromObject = false;
    tooltip.background.fill = am4core.color('#000000');
    tooltip.label.minHeight = 70;
    tooltip.background.strokeOpacity = 0;
    series.tooltipHTML = `${toolTipString}  </table>`;
  }
  series.showOnInit = true;
}

export function formatHisTrendChartData(responseData) {
  const labels = [
    'Export',
    'Non-Power: GMES',
    'Non-Power: Others',
    'Non-Power: PIC',
    'Power',
    'Total Demand',
    'Total Supply',
  ];
  if (responseData?.length) {
    const chartData: object[] = [];
    const times: any[] = [];
    for (const i of responseData) {
      for (const k of i.chartData) {
        const time = moment(k.date);
        times.push(time);
      }
    }
    const latestHour = moment.max(times);

    for (let i = 12; i >= 0; i--) {
      const latestTime = latestHour;
      if (i !== 12) {
        latestTime.subtract(1, 'hour');
      }
      chartData[i] = {
        hour: latestTime.format('HH'),
      };
    }

    for (const i of responseData) {
      for (const k of i.chartData) {
        const hour = moment(k.date).format('HH');
        const dataIndex = chartData.findIndex((c: any) => c.hour === hour);
        chartData[dataIndex] = {
          ...chartData[dataIndex],
          [i?.legendInfo?.label?.trim()]: Math.round(k.value),
        };
      }
    }

    for (let c = 0; c < chartData.length; c++) {
      for (let l of labels) {
        if(!chartData?.[c]?.[l]) {
          chartData[c] = {...chartData?.[c], [l] : 0}
        }
      }
    }

    for (let c = 0; c < chartData.length; c++) {
     if(c === 0 || ["12", "00"].includes(chartData[c]['hour'])) {
      chartData[c] = {...chartData[c], hour: moment(chartData[c]['hour'], 'HH').format('hh a'), formatHour: moment(chartData[c]['hour'], 'HH').format('hh:mm a')}
     }else{
      chartData[c] = {...chartData[c], hour: moment(chartData[c]['hour'], "HH").format('hh'), formatHour: moment(chartData[c]['hour'], 'HH').format('hh:mm a')}
     }
    }
    
    return chartData;
  }
}
