import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import s from '../AssuranceLines.module.css';
import { fetchAvailablePeriodRequest, fetchGasBusinessFLA, fetchGCOFLA, fetchGPFLA, fetchLMTFLA, fetchLNGAFLA } from "../../../../action/actionRisk";
import FirstLineGBTableComponent from './FirstLineGBTableComponent';
import FirstLineActionItemComponent from './FirstLineActionItemComponent';
import { Progress } from 'antd';

export interface AssuranceLineProps {
  data: {
    opu: number;
    line: number;
  };
  onChangeTab: (tab: number) => void;
  opuTabArr: { label: string, value: number }[];
  fromDate: {
    year: number;
    month: number;
  };
  toDate: {
    year: number;
    month: number;
  }
}

const AssuranceFirstLineComponent = ({ data, onChangeTab, opuTabArr, fromDate, toDate }: AssuranceLineProps) => {
  const dispatch = useDispatch();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setHasData(false); // Clear data when OPU changes
    switch (data.opu) {
      case 0:
        dispatch(fetchGasBusinessFLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 1:
        dispatch(fetchLNGAFLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 2:
        dispatch(fetchGPFLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      case 3:
      dispatch(fetchLMTFLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
      setHasData(true);
        break;
      case 4:
        dispatch(fetchGCOFLA(`?year=${fromDate.year}&fromMonth=${fromDate.month}&toMonth=${toDate.month}`));
        setHasData(true);
        break;
      default:
        break;
    }
  }, [dispatch, data.opu, data.line, fromDate, toDate]);

  const requirementInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceRequirementCompliance', [])
  );

  const scheduleInfo = useSelector((state) =>
    get(state, 'risk.myAssuranceInfo.result.assuranceScheduleCompliance', [])
  );

  const renderOverallSection = (type: string) => {
    const getCircleColorClass = (value) => {
      if (value > 80) return '#00FF5D';
      if (value > 50 && value <= 80) return '#FDB924';
      return '#DA2228';
    };
    
    const getDotColorClass = (value) => {
      if (value > 80) return s.greenDot;
      if (value > 50 && value <= 80) return s.yellowDot;
      if (value == 0) return s.greyDot;
      return s.redDot;
    };

    const getOverallLabel = (value) => {
      if (value > 80) return "Effective";
      if (value > 50 && value <= 80) return "Some Improvement Needed";
      if (value == 0) return "No Data";
      return "Major Improvement Needed";
    };

    const overallPercentage = type === 'requirement' ? requirementInfo?.overallPercentage?.value : scheduleInfo?.overallPercentage?.value;
    const circleColorClass = getCircleColorClass(overallPercentage);
    const dotColorClass = getDotColorClass(overallPercentage);
    const overallLabel = getOverallLabel(overallPercentage);

    return (
      <div className={s.overallSection}>
        <div className={s.overallHeader}>
          Overall
        </div>
        <div className={s.infoLabel}>
          <span className={`${s.dot} ${dotColorClass}`}></span>
          <span style={{opacity: '0.6'}}>{overallLabel}</span>
          <div className={s.tooltipWrapper}>
            <img src='/img/icon/ic_info_outline.svg' alt="Requirement Tooltip" className={s.infoIcon} />
            <div className={s.headerTooltipContainer}>
              <img src="img/tooltip/overall-circle-tooltip.svg" alt="Requirement Tooltip" />
            </div>
          </div>
        </div>
        <div className={`${s.overallCircle} ${circleColorClass}`}>
          <Progress
            type="circle"
            className={s.percentage}
            percent={overallPercentage}
            strokeColor={circleColorClass}
            trailColor="#365F74"
            format={() => <p>{overallPercentage}%</p>}
            width={90}
            strokeWidth={7}
          />
        </div>
      </div>
    );
  }

  const renderContent = (type: string) => {
    const info = type === "requirement" ? requirementInfo : scheduleInfo;
    if (!hasData || !info || info.length === 0) {
      return <div className={s.noResult}>
      <img src="/img/icon/no-result.svg" alt="No results" />
      <h2>No data available!</h2>
      </div>;
    }
    return (
      <div className={s.contentContainer}>
        <div className={s.flexContainer}>
          {renderOverallSection(type)}
          <FirstLineActionItemComponent type={type}/>
        </div>
        <FirstLineGBTableComponent
          assuranceData={info}
          opu={data.opu}
          onChangeTab={onChangeTab}
          opuTabArr={opuTabArr}
          type={type}
        />
        {/* Add other OPU Table Component when asset mapping is done */}
      </div>
    );
  };

  const renderBase = () => {
    return (
      <>
        <div className={s.requirement}>
          <div className={s.header}>
            <span>Requirement Compliance</span>
            <div className={s.tooltipWrapper}>
              <img alt='info' src='/img/icon/ic_info_outline.svg' className={s.infoIcon} />
              <div className={s.headerTooltipContainer}>
                <img src="img/tooltip/requirement-compliance-tooltip.svg" alt="Requirement Tooltip" />
              </div>
            </div>
          </div>
          {renderContent("requirement")}
        </div>

        <div className={s.schedule}>
          <div className={s.header}>
            <span>Schedule Compliance</span>
            <div className={s.tooltipWrapper}>
              <img alt='info' src='/img/icon/ic_info_outline.svg' className={s.infoIcon} />
              <div className={s.headerTooltipContainer}>
                <img src="img/tooltip/schedule-compliance-tooltip.svg" alt="Schedule Tooltip" />
              </div>
            </div>
          </div>
          {renderContent("schedule")}
        </div>
      </>
    );
  };

  return renderBase();
};

export default AssuranceFirstLineComponent;