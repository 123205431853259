import React, { useEffect, useState } from 'react'
import s from './ErrorStatusModal.module.css'
import { Modal, Table } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { fetchScorecardListHistoryError } from '../../../action/actionScoreCard';

interface Iprop {
    historyId: number,
    location: string,
    isShowErrorModal: boolean,
    setIsShowErrorModal: (value: boolean) => void
}

const ErrorStatusModal = ({ historyId, location, isShowErrorModal, setIsShowErrorModal }: Iprop) => {
    const historyErrorList  = useSelector((state: any) => state.scoreCard.historyErrorList)
    const dispatch = useDispatch()

    function renderErrorTableColumn() {
        return [
            {
                title: "Sheet",
                dataIndex: "sheetName",
                key: 'sheetName',
                width: 250,
                ellipsis: true,
                sorter: (a: any, b: any) => a.sheetName.localeCompare(b.sheetName),
            },
            {
                title: "Column",
                dataIndex: "column",
                key: 'column',
                width: 130,
                ellipsis: true,
                sorter: (a: any, b: any) => a.column - b.column,
            },
            {
                title: "Row",
                dataIndex: "row",
                key: 'row',
                width: 130,
                ellipsis: true,
                sorter: (a: any, b: any) => a.row - b.row,
            },
            {
                title: "Description",
                dataIndex: "description",
                key: 'description',
                width: 500,
                ellipsis: true,
            },
        ]
    }

    function renderModalHeader() {
        return (
            <div className={s.errorModalHeader}>
                <span>{`${location} Error`}</span>
                <img
                    onClick={() => { setIsShowErrorModal(false) }}
                    src="/img/icon/CrossPopup.svg"
                    alt="close"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setIsShowErrorModal(false);
                        }
                    }}
                    tabIndex={0}
                />
            </div>
        )
    }

    useEffect(() => {
        if (historyId) {
            dispatch(fetchScorecardListHistoryError(`?historyId=${historyId}`));
        }
    }, [historyId])

    return (
        <>
            <Modal
                className="errorStatusModal"
                closable={false}
                visible={isShowErrorModal}
                footer={null}
                width={1132}
                style={{ top: '49vh' }}
            >
                <div className={s.errorModalContainer}>
                    {renderModalHeader()}
                    <div className={s.divider} />
                    <div className={s.errorModalBody}>
                        <Table
                            className="error-table"
                            rowClassName={(_record, index) => index % 2 === 0 ? s.tableStripe : ''}
                            columns={renderErrorTableColumn()}
                            dataSource={historyErrorList}
                            pagination={{
                                pageSize: 4,
                                position: ["bottomLeft"],
                                style: { marginTop: 20 },
                                showTotal: (total, range) => <div className={s.totalPagination}><b>{range[0]}-{range[1]}</b>{` of ${total} items`}</div>,
                                showSizeChanger: false,
                            }}
                        />
                    </div>
                    <div className={s.resolveSteps}>
                        <span>How to Resolve:</span>
                        <ol>
                            <li>Go to the specified sheet, row, and column in the file.</li>
                            <li>Fix the value as per the error description (e.g., correct the data format).</li>
                            <li>Re-upload the corrected file.</li>
                        </ol>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ErrorStatusModal