import * as types from './actionType';

export const fetchRiskTop = (payload?: any) => ({
    type: types.GET_RISK_TOP_REQUESTED,
    payload
});

export const fetchAvailablePeriodRequest = () => ({
    type: types.GET_RISK_AVAILABLE_PERIOD_REQUESTED,
  });
  
export const fetchAvailablePeriodSuccess = (data) => ({
  type: types.GET_RISK_AVAILABLE_PERIOD_SUCCESS,
  payload: data,
});

export const fetchRiskKRICallout = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskKRICalloutSuccess = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchRiskMitigationCallout = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskMitigationCalloutSuccess = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchGasBusinessFLA = (data) => ({
  type: types.GET_GAS_BUSINESS_FLA_REQUESTED,
  payload: data,
});

export const fetchGasBusinessFLASuccess = (data) => ({
  type: types.GET_GAS_BUSINESS_FLA_SUCCESS,
  payload: data,
});

export const fetchLNGAFLA = (data) => ({
  type: types.GET_LNGA_FLA_REQUESTED,
  payload: data,
});

export const fetchLNGAFLASuccess = (data) => ({
  type: types.GET_LNGA_FLA_SUCCESS,
  payload: data,
});

export const fetchGPFLA = (data) => ({
  type: types.GET_GP_FLA_REQUESTED,
  payload: data,
});

export const fetchGPFLASuccess = (data) => ({
  type: types.GET_GP_FLA_SUCCESS,
  payload: data,
});

export const fetchLMTFLA = (data) => ({
  type: types.GET_LMT_FLA_REQUESTED,
  payload: data,
});

export const fetchLMTFLASuccess = (data) => ({
  type: types.GET_LMT_FLA_SUCCESS,
  payload: data,
});

export const fetchGCOFLA = (data) => ({
  type: types.GET_GCO_FLA_REQUESTED,
  payload: data,
});

export const fetchGCOFLASuccess = (data) => ({
  type: types.GET_GCO_FLA_SUCCESS,
  payload: data,
});
