import * as types from "../action/actionType";

import { APP_CONSTANTS } from "../constants/app";
import { Action } from "redux";
import { getTabDefault } from "../utils/Url.utils";
import queryString from "query-string";

const params = queryString.parse(window.location.search);

export const INITIAL_STATE = {
  currentLocationId:
    params && params.location ? Number(params.location) : APP_CONSTANTS.DEFAULT_LOCATION_ID,
  selectedPointId: params && params.id ? params.id : null,
  mapLevel:
    params && params.level ? Number(params.level) : APP_CONSTANTS.DEFAULT_LEVEL,
  mapData: {},
  isLoading: true,
  activeTab: getTabDefault(),
  riskInfo: [],
  availablePeriod: [],
  kriCallout: [],
  mitigationCallout: [],
  myAssuranceInfo: []
};

interface RiskAction extends Action {
  data: string | any;
  payload: any;
}

export function riskReducer(state = INITIAL_STATE, action: RiskAction) {
  switch (action.type) {
    case types.GET_RISK_TOP_SUCCESS: {
      return {
        ...state,
        riskInfo: action.payload
      }
    }
    case types.GET_RISK_TOP_FAIL: {
      return {
        ...state,
        riskInfo: null
      }
    }
    case types.GET_RISK_AVAILABLE_PERIOD_SUCCESS: {
      return {
        ...state,
        availablePeriod: action.payload,
      };
    }
    case types.GET_RISK_KRI_CALLOUT_SUCCESS: {
      return {
        ...state,
        kriCallout: action.payload
      }
    }
    case types.GET_RISK_KRI_CALLOUT_FAIL: {
      return {
        ...state,
        kriCallout: null
      }
    }
    case types.GET_RISK_MITIGATION_CALLOUT_SUCCESS: {
      return {
        ...state,
        mitigationCallout: action.payload
      }
    }
    case types.GET_RISK_MITIGATION_CALLOUT_FAIL: {
      return {
        ...state,
        mitigationCallout: null
      }
    }
    case types.GET_GAS_BUSINESS_FLA_SUCCESS: {
      return {
        ...state,
        myAssuranceInfo: action.payload
      }
    }
    case types.GET_GAS_BUSINESS_FLA_FAIL: {
      return {
        ...state,
        myAssuranceInfo: null
      }
    }
    case types.GET_LNGA_FLA_SUCCESS: {
      return {
        ...state,
        myAssuranceInfo: action.payload
      }
    }
    case types.GET_LNGA_FLA_FAIL: {
      return {
        ...state,
        myAssuranceInfo: null
      }
    }
    case types.GET_GP_FLA_SUCCESS: {
      return {
        ...state,
        myAssuranceInfo: action.payload
      }
    }
    case types.GET_GP_FLA_FAIL: {
      return {
        ...state,
        myAssuranceInfo: null
      }
    }
    case types.GET_LMT_FLA_SUCCESS: {
      return {
        ...state,
        myAssuranceInfo: action.payload
      }
    }
    case types.GET_LMT_FLA_FAIL: {
      return {
        ...state,
        myAssuranceInfo: null
      }
    }
    case types.GET_GCO_FLA_SUCCESS: {
      return {
        ...state,
        myAssuranceInfo: action.payload
      }
    }
    case types.GET_GCO_FLA_FAIL: {
      return {
        ...state,
        myAssuranceInfo: null
      }
    }
  }
  return state;
}
