import { ENDPOINTS } from "../constants/endpoints";
import axiosClient from "./axiosClient.service";

export function getRiskTop(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_PROFILE+ (payload === undefined ? '' : payload))
}

export function getAvailablePeriod(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_AVAILABLE_PERIOD + (payload === undefined ? '' : payload))
}

export function getRiskKRICallout(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_KRI_CALLOUT + (payload === undefined ? '' : payload))
}

export function getRiskMitigationCallout(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_RISK_MITIGATION_CALLOUT + (payload === undefined ? '' : payload))
}

export function getGasBusinessFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GAS_BUSINESS_FLA + (payload === undefined ? '' : payload))
}

export function getLNGAFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LNGA_FLA + (payload === undefined ? '' : payload))
}

export function getGPFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GP_FLA + (payload === undefined ? '' : payload))
}

export function getLMTFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_LMT_FLA + (payload === undefined ? '' : payload))
}

export function getGCOFLA(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_GCO_FLA + (payload === undefined ? '' : payload))
}