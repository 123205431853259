import LimitConfigurationComponent from "../../container/admin/LimitConfiguration/LimitConfigurationComponent";
import ScorecardsOrdering from "../../container/admin/ScorecardsOrdering/ScorecardsOrderingComponent";
import UserManagementComponent from "../../container/admin/Usermanagement/UserManagementComponent";
import MapComponent from "../../container/Map/MapComponent";
import MftComponent from "../../container/Mft/MftComponent";
import ScoreCardsComponent from "../../container/ScoreCards/ScoreCardsComponent";
import ValueChainComponent from "../../container/ValueChain/ValueChainComponent";
import ReleaseContentComponent from "../../container/admin/ReleaseContent/ReleaseContentComponent";
import { URL_SIDE_BAR } from "../map.constants";
import DataHealthComponent from "../../container/DataHealth/DataHealthComponent";
import SustainabilityComponent from "../../container/Sustainability/SustainabilityComponent";
import ScorecardManagement from "../../container/admin/ScorecardManagement/ScorecardManagement";
import RiskProfileComponent from "../../container/RiskManagement/RiskProfile/RiskProfileComponent";
import AssuranceComponent from "../../container/RiskManagement/MyAssurance/AssuranceComponent";

export const GNE_ADMIN_ROUTE = [
  {
    path: URL_SIDE_BAR.USERMANAGEMENT,
    component: UserManagementComponent,
    exact: true,
    icon: "/img/icon/usermanagements.svg",
    alt: "usermanagement",
    text: "User Management",
    isAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.LIMITCONFIGURATION,
    component: LimitConfigurationComponent,
    exact: true,
    icon: "/img/icon/limitconfigs.svg",
    alt: "limitconfig",
    text: "Limit Configuration",
    isAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.SCORECARDORDERING,
    component: ScorecardsOrdering,
    exact: true,
    icon: "/img/icon/scorecardorderings.svg",
    alt: "scorecardsordering",
    text: "Scorecard Ordering",
    isAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.SCORECARD_MANAGEMENT,
    component: ScorecardManagement,
    exact: true,
    icon: "/img/icon/ScorecardManagement_grey.svg",
    alt: "scoremanagement",
    text: "Scorecard Management",
    isAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.MAP,
    component: MapComponent,
    exact: true,
    iconActive: "/img/icon/map-active.svg",
    icon: "/img/icon/map-default.svg",
    alt: "Toggle map",
    text: "Map",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.VALUE_CHAIN,
    component: ValueChainComponent,
    exact: true,
    iconActive: "/img/icon/value-chain-active.svg",
    icon: "/img/icon/value-chain-default.svg",
    alt: "Value Chain",
    text: "Value Chain",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.SCORECARDS,
    component: ScoreCardsComponent,
    exact: true,
    iconActive: "/img/icon/scorecard-active.svg",
    icon: "/img/icon/scorecard-default.svg",
    alt: "Scorecards",
    text: "Scorecards",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.MFT,
    component: MftComponent,
    exact: true,
    iconActive: "/img/icon/mft-active2.svg",
    icon: "/img/icon/mft-default.svg",
    alt: "MFT",
    text: "MFT",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.RELEASE_CONTENT,
    component: ReleaseContentComponent,
    exact: true,
    icon: "/img/icon/release-content.svg",
    alt: "releasecontent",
    text: "Release Content",
    isAdmin: true,
    isSuperAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.DATA_HEALTH_DASHBOARD,
    component: DataHealthComponent,
    exact: true,
    icon: "/img/icon/datahealths.svg",
    alt: "datahealthdashboard",
    text: "Data Health Dashboard",
    isAdmin: true,
    isSuperAdmin: true,
    base: false,
  },
  {
    path: URL_SIDE_BAR.SUSTAINABILITY,
    component: SustainabilityComponent,
    exact: true,
    iconActive: "/img/icon/map-active.svg",
    icon: "/img/icon/map-default.svg",
    alt: "Toggle Sustainability",
    text: "Sustainability",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.RISK_PROFILE,
    component: RiskProfileComponent,
    exact: true,
    iconActive: "/img/icon/riskProfile-active.svg",
    icon: "/img/icon/riskProfile.svg",
    alt: "Risk Profile",
    text: "Risk Profile",
    isAdmin: false,
    base: true,
  },
  {
    path: URL_SIDE_BAR.ASSURANCE,
    component: AssuranceComponent,
    exact: true,
    iconActive: "/img/icon/Admin-green.svg",
    icon: "/img/icon/Admin-grey.svg",
    alt: "Assurance",
    text: "Assurance",
    isAdmin: false,
    base: true,
  }
];
