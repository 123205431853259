import * as types from './actionType';

export const setSelectedPersona = (payload) => ({
  type: types.USER_SET_SELECTED_PERSONA,
  payload: payload,
});

export const setSelectedPersonaViewUrl = (payload) => ({
  type: types.USER_SET_SELECTED_PERSONA_VIEWURL,
  payload: payload,
});

export const fetchOPUsUserAccess = () => ({
  type: types.USER_ACCESS_GET_OPUS,
});

export const fetchAdminsUserAccess = () => ({
  type: types.USER_ACCESS_GET_ADMINS,
});

export const submitUserAccess = (payload) => ({
  type: types.USER_ACCESS_SUBMIT,
  payload: payload,
});
